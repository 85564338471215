import MenuItems from './MenuItems';
import {motion} from "framer-motion";

const Dropdown = ({submenus, dropdown, depthLevel}) => {
  depthLevel = depthLevel + 1;
  const dropdownClass = depthLevel > 1 ? 'dropdown-submenu' : '';

  const subMenuAnimate = {
    enter: {
      opacity: 1,
      rotateY: 0,
      transition: {
        duration: 0.3
      },
      display: "block"
    },
    exit: {
      opacity: 0,
      rotateX: -15,
      transition: {
        duration: 0.3,
        delay: 0.3
      },
      transitionEnd: {
        display: "none"
      }
    }
  };

  return (
    <motion.ul
      variants={subMenuAnimate}
      animate={dropdown ? 'enter' : 'exit'}
      initial={'exit'}
      style={{
        boxShadow: "0px 7.94708px 24px -2px rgba(0, 0, 0, 0.25)"
      }}
      className={`dropdown ${dropdown ? "show" : ""}`}
    >
      {submenus.map((submenu, index) => (
        <MenuItems
          items={submenu}
          key={index}
          depthLevel={depthLevel}
        />
      ))}
    </motion.ul>
  );
};

export default Dropdown;

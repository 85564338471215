import React, {useRef} from 'react'
import User from '../../assets/user.svg';
import Email from '../../assets/email.svg'
import Phone from '../../assets/phone.svg'
import Messege from '../../assets/messege.svg'
import InputMask from 'react-input-mask';
import Upload from "../../assets/upload.svg";
import {useTranslation} from "react-i18next";

const styles = {
  fontFamily: 'sans-serif',
  textAlign: 'center',
  display: 'flex',
  flexWrap: 'wrap',
};

export default function CommonForm(
  {
    name, email, phone, fileName, message, changeName, changeEmail, changePhoneNumber, changeMessage, submit, fileChange
  }
) {
  const {t} = useTranslation()

  return (
    <form onSubmit={submit} className='flex flex-wrap gap-5 md:gap-3 mb-20'>
      <div className='relative w-[32%] flex-grow sm:w-full hover:scale-105 duration-300'>
        <img width={'18px'} src={User} alt="user icon" className='absolute top-5 left-3 md:top-3'/>
        <input
          value={name}
          onChange={e => changeName(e.target.value)}
          className='hover:shadow-xl text-xl  px-4 py-4  border-none rounded-lg  md:px-2 md:py-2 w-full pl-9 md:pl-9'
          style={{boxShadow: "0 0 20px 0 rgb(24 50 67 / 12%)"}}
          type="text"
          placeholder={t('organization-name')}
          name='name'
        />
      </div>

      <div className='relative w-[32%] flex-grow sm:w-full hover:scale-105 duration-300'>
        <img width={'18px'} src={Email} alt="email icon" className='absolute top-5 left-3 md:top-3'/>
        <input
          value={email} onChange={e => changeEmail(e.target.value)}
          className='hover:shadow-xl text-xl  px-4 py-4 border-none rounded-lg  md:px-2 md:py-2 w-full pl-9 md:pl-9'
          style={{boxShadow: "0 0 20px 0 rgb(24 50 67 / 12%)"}}
          type="email"
          placeholder={t('email')}
          name='email'
        />
      </div>

      <div className='relative w-[32%] flex-grow sm:w-full hover:scale-105 duration-300'>
        <img width={'18px'} src={Phone} alt="phone icon" className='absolute top-5 left-3 md:top-3'/>
        <InputMask mask={'+999 (99) 999 99 99'}
          value={phone} onChange={e => changePhoneNumber(e.target.value)}
          className='hover:shadow-xl text-xl  px-4 py-4 border-none rounded-lg  md:px-2 md:py-2 w-full pl-9 md:pl-9'
          style={{boxShadow: "0 0 20px 0 rgb(24 50 67 / 12%)"}}
          type="tel"
          placeholder={t('phone')} name='phone_number'
        />
      </div>

      <div className='relative w-[66%] flex-grow sm:w-full hover:scale-105 duration-300'>
        <img width={'18px'} src={Messege} alt="messege icon" className='absolute top-5 left-3 md:top-3'/>
        <textarea
          value={message}
          onChange={e => changeMessage(e.target.value)}
          className='hover:shadow-xl text-xl  px-4 py-4 border-none rounded-lg w-full h-full md:px-2 md:py-2 pl-9 md:pl-9'
          style={{boxShadow: "0 0 20px 0 rgb(24 50 67 / 12%)"}}
          placeholder={t('message')}
          name='message'
        />
      </div>

      <div className='flex flex-col w-[32%] flex-grow items-start'>
        <div className='flex flex-col items-start'>
          <label className='mb-2' htmlFor="file1">Fayl yuborish (doc/pdf/docx)</label>
          <label className="custom-file-upload">
            <input id="max_id" type="hidden" name="MAX_FILE_SIZE" value="5245880"/>
            <input
              onChange={fileChange}
              accept='.doc,.pdf,.docx'
              type="file"
            />
            <img className='mr-2' src={Upload} alt="upload icon"/>
            {t('file-upload')}
          </label>
          {fileName}
        </div>
        <button
          className='px-6 text-xl py-2 bg-logo border-logo border-2 border-solid hover:bg-white  text-white hover:text-logo font-semibold rounded-full mt-3  md:px-2 md:mt-2 transition'
          type={'submit'}
        >
          {t('send')}
        </button>
      </div>
    </form>
  )
}

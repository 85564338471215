import React, { useEffect, useState } from "react";
import CommonSection from "../../../components/Layout/CommonSection";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getPurches } from "../../../redux/actions/actions";
import { useTranslation } from "react-i18next";
import trophy from "../../../assets/trophy.png";
import Image from "../../../assets/no-results-bg.2d2c6ee3.png";

export default function Info() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const location1 = useLocation();

  const { location } = useSelector((state) => state.location);
  const { purches } = useSelector((state) => state.purches);

  useEffect(() => {
    dispatch(
      getPurches(
        location1.pathname === "/purches-info"
          ? "PURINF"
          : location1.pathname === "/select-info"
          ? "ELECTIONS"
          : "TENDER"
      )
    );
  }, [location1, dispatch]);

  const [openIndex, setOpenIndex] = useState(null);

  const handleToggle = (index) => {
    setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <>
      <CommonSection
        title={
          location1.pathname === "/purches-info"
            ? location === "uz"
              ? "Takliflar tanlovi"
              : location === "ru" ? "Отбор предложений" : "A selection of offers"
            : location1.pathname === "/select-info"
            ? location === "uz"
              ? "Tenderlar"
              : location === "ru" ? "Тендеры" : "Tenders"
            : location === "uz"
            ? "Natijalar"
            : location === "ru" ? "Результаты" : "Results"
        }
      />
      <section>
        <div className="container mx-auto pt-5">
          {/* <h2 className="text-xl font-semibold text-center mb-5">КОНКУРС UZ</h2> */}
          {purches.length === 0 ? (
            <div className={"mx-auto w-full text-center"}>
              <img src={Image} alt="" className={"w-1/4 mx-auto"} />
              <h1 className={"text-3xl"}>{t("soon")}</h1>
            </div>
          ) : (
            purches &&
            purches?.map((e, index) => (
              <div
                key={index}
                className="w-full flex flex-col p-5 mb-5"
                style={{ boxShadow: "0 0 20px 0 rgb(24 50 67 / 12%)" }}
              >
                <div className="w-full flex justify-between lg:flex-col mb-5">
                  <div className="">
                    <p className="text-hover">{e.status}</p>
                    <h3 className="font-medium text-logo mb-5">
                      {location === "uz" ? e.title_uz : location === "ru" ? e.title_ru : e.title_en}
                    </h3>
                    <hr className="w-14 mb-5" />
                    <p className="mb-5">
                      {location === "uz" ? e.text_uz : location === "ru" ? e.text_ru : e.text_en}
                    </p>
                  </div>

                  <div className="w-1/3 flex flex-wrap items-end justify-end lg:w-full lg:pl-0 lg:pt-5 lg:justify-start">
                    <div className="border-l border-solid border-bd pl-4 lg:border-0 lg:pl-0">
                      {e.sum_view && (
                        <p>
                        {t("sum")}:{" "}
                        {(e.sum !== null || e.price !== null) && (
                          <span className="inline-block p-2 bg-[#e5f3ff] text-logo font-semibold ml-2 mb-5">
                            {e.sum} {e.price}
                          </span>
                        )}
                      </p>
                      )}
                      <p>
                        Boshlangan sana:{" "}
                        <span className="inline-block p-2 bg-[#e5f3ff] text-logo font-semibold ml-2 mb-5">
                          {e.start_date}
                        </span>
                      </p>
                      <p>
                        <span className="mr-[26px]">Tugash sanasi:</span>{" "}
                        <span className="inline-block p-2 bg-[#e5f3ff] text-logo font-semibold ml-2">
                          {e.end_date}
                        </span>
                      </p>
                    </div>
                    <div
                      className={
                        "flex flex-col gap-2 items-center w-[40%] mt-5 ml-auto"
                      }
                    >
                      {e.status === "Aktiv" && e.url ? (
                        <>
                          <a
                            rel="noreferrer"
                            className="flex items-center gap-2 mt-2 justify-center"
                            target="_blank"
                            href={e.url}
                          >
                            <img
                              src={trophy}
                              alt="trophy"
                              className={"w-[10%]"}
                            />
                            Qatnashish
                          </a>
                        </>
                      ) : null}
                      <button
                        onClick={() => handleToggle(index)}
                        className="ml-auto px-4 py-2 mt-3 rounded-[4px] bg-[#0E0E4B] text-white xs:px-2 xs:py-1 justify-center duration-200 opacity-70 hover:opacity-100"
                      >
                        {t("more")}
                      </button>
                    </div>
                  </div>
                </div>
                {openIndex === index && (
                  <p>
                    {t("more")}: {location === "uz" ? e.note_uz : location === "ru" ? e.note_ru : e.note_en}
                  </p>
                )}
              </div>
            ))
          )}
        </div>
      </section>
    </>
  );
}

import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { sendMistakeInfo } from "../../redux/actions/actions";
import "./index.css";

const EditTextModal = ({ text, open, setOpen, slug }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [describtion, setDescribtion] = useState("");

  const handleClick = (e) => {
    e.preventDefault();
    if (text) {
      dispatch(sendMistakeInfo({ word: text, comment: describtion, slug }));
      toast.success(t("has_been_sent"));
      setDescribtion("");
      setOpen(false)
    }
  };

  return (
    <div
      className={`edit-text-modal ${open ? "flex" : "hidden"}`}
    >
      <div className="dialog-content">
        <div className="flex justify-end">
            <button onClick={() => setOpen(false)} className="bg-white px-1 py-1 rounded-full translate-x-8"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="currentColor"><path d="M11.9997 10.5865L16.9495 5.63672L18.3637 7.05093L13.4139 12.0007L18.3637 16.9504L16.9495 18.3646L11.9997 13.4149L7.04996 18.3646L5.63574 16.9504L10.5855 12.0007L5.63574 7.05093L7.04996 5.63672L11.9997 10.5865Z"></path></svg></button>
        </div>
        <div className="dialog-wrap">
          <h3>{t("report_grammatical_errors")}</h3>
          <input
            className="w-full p-3 mb-5 border-bd border-2 border-solid rounded"
            placeholder={t("describe_errors")}
            type="text"
            value={describtion}
            onChange={(e) => setDescribtion(e.target.value)}
          />
          <p>{t("sent_to_editorial_office")}</p>
          <div className="editional-section-wrapper">
            <p className="editional-section">{text}</p>
          </div>
        </div>
        <div className="mistape_dialog_block">
          <a
            onClick={handleClick}
            className="mistape_action"
            role="button"
            data-action="send"
          >
            {t("send")}
          </a>
        </div>
      </div>
    </div>
  );
};

export default EditTextModal;

import React from 'react';

const Tour = () => {

  return (
    <>
      <div className="container">
        <iframe src="https://66cdb253baf6eae16efb7f67--idyllic-cat-cefb1d.netlify.app/" className="w-full h-screen py-4"
                frameBorder={0}
        ></iframe>
      </div>
    </>
  );
};

export default Tour;
import React, { useEffect, useRef, useState } from "react";
import CommonSection from "../../components/Layout/CommonSection";
import { Link } from "react-router-dom";
import VanillaTilt from "vanilla-tilt";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getServices } from "../../redux/actions/actions";
import { useTranslation } from "react-i18next";

function Tilt(props) {
  const { options, ...rest } = props;
  const tilt = useRef(null);

  useEffect(() => {
    // VanillaTilt.init(tilt.current, options);
  }, [options]);

  return <div ref={tilt} {...rest} />;
}

export default function Departments() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const { location } = useSelector((state) => state.location);

  const [pose, setPose] = useState("BD");

  // const options = {
  //   scale: 1.1,
  //   speed: 2000,
  //   max: 10,
  // };
  useEffect(() => {
    const fetchNavbar = () => {
      setLoading(true);
      try {
        dispatch(getServices(pose));
        setLoading(false);
      } catch (e) {
        toast.error(e.message);
        // console.log(e);
        setLoading(false);
      }
    };
    fetchNavbar();
  }, [pose, dispatch]);
  const { bolimlar } = useSelector((state) => state.bolimlar);

  return (
    <>
      <div className="products">
        <CommonSection title={t("bolim")} breadCrumb={t("bolim")} />
        <div className="container mx-auto pt-6">
          <select
            class="block py-2.5 px-0 w-full text-lg text-gray-500 bg-transparent border-0 border-b-2 border-gray-200 appearance-none dark:text-gray-400 dark:border-gray-700 focus:outline-none focus:ring-0 focus:border-gray-200 peer"
            name="bolim"
            value={pose}
            onChange={(e) => setPose(e.target.value)}
          >
            <option value="BD">{t("BD")}</option>
            <option value="KRIPTO">{t("KRIPTO")}</option>
            <option value="INFRA">{t("INFRA")}</option>
            <option value="ATR">{t("ATR")}</option>
            <option value="ILTAD">{t("ILTAD")}</option>
            <option value="SERTIFIKAT">{t("SERTIFIKAT")}</option>
            <option value="MOLIYA">{t("MOLIYA")}</option>
          </select>

          <div className="box py-6 flex gap-14 flex-wrap">
            {bolimlar &&
              bolimlar?.map((item) => (
                <Tilt
                  style={{ border: "1px solid rgb(171, 184, 195)" }}
                  key={item.id}
                  className="py-4 hover:shadow-xl px-5 flex w-[30%] gap-5 md:w-[45%] md:flex-wrap products min-h-[140px]"
                  // options={options}
                >
                  <div className="flex w-full flex-col gap-5">
                    <div className="flex justify-start gap-5">
                      {/* <img
                        src={item.icon}
                        alt=""
                        className="w-[53px] object-cover h-[53px]"
                      /> */}
                      <h1>{location === "uz" ? item.name_uz : location === "ru" ? item.name_ru : item.name_en}</h1>
                    </div>

                    <div className="flex justify-between mt-auto">
                      <p className="department-name text-lg font-medium text-logo-secondary capitalize">
                        {t("department")} id: <span>{item.index}</span>
                      </p>

                      <Link
                        className="flex items-center gap-2 px-4 py-2 mt-3 rounded-[4px] bg-[#0E0E4B] text-white xs:px-2 xs:py-1 justify-center duration-200 opacity-70 hover:opacity-100"
                        to={`/services/${item.id}`}
                      >
                        {t("more")}
                      </Link>
                    </div>
                  </div>
                </Tilt>
              ))}
          </div>
        </div>
      </div>
    </>
  );
}

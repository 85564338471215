import React, {useEffect, useState} from 'react'
import {Outlet} from 'react-router-dom'
import Navbar from "../Header/Navbar";
import Footer from "./Footer";

const Layout = () => {
  const [toTop, setToTop] = useState(false)

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY > 200) {
        setToTop(true)
      } else {
        setToTop(false)
      }
    })
  }, [])

  return (
    <div>
      <div
        onClick={() => window.scrollTo(0, 0)}
        className='hover:-translate-y-2 duration-200 fixed z-50 bottom-24 right-10 xs:right-4 xs:bottom-10 bg-white w-12 h-12 rounded-full flex justify-center items-center cursor-pointer'
        style={{
          opacity: toTop ? 1 : 0,
          pointerEvents: toTop ? 'all' : 'none',
          boxShadow: "0px 7.94708px 24px -2px rgba(0, 0, 0, 0.25)"
        }}
      >
          <span className='text-4xl text-[#00314C]'>
            <i className="ri-arrow-up-s-line"></i>
          </span>
      </div>
      <Navbar/>
      <div className="content">
        <Outlet/>
      </div>
      <Footer/>
    </div>
  )
}

export default Layout

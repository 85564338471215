import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getSingleNews } from "../redux/actions/actions";
import CommonSection from "./Layout/CommonSection";
import parse from "html-react-parser";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation, EffectCoverflow } from "swiper";
import "swiper/css/effect-coverflow";
import moment from "moment";

function VideoDetails() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { singleNew } = useSelector((state) => state.news);
  const { content_ru, content_uz, content_en } = useSelector(
    (state) => state.news.singleNew
  );
  const { location } = useSelector((state) => state.location);
  const [width, setWidth] = useState(3);

  useEffect(() => {
    const fetchSingleNews = async () => {
      await dispatch(getSingleNews(id));
    };
    fetchSingleNews();
    changeWindow();
  }, [id, dispatch]);

  useEffect(() => window.scrollTo(0, 0), []);

  const changeWindow = () => {
    window.addEventListener("resize", () => {
      if (window.innerWidth < 768) {
        return setWidth(1);
      } else if (window.innerWidth < 1024) {
        return setWidth(2);
      } else {
        return setWidth(3);
      }
    });
  };

  if (!singleNew) return "Loading...";

  return (
    <>
      <div>
        <CommonSection
          title={location === "uz" ? singleNew.title_uz : location === "ru" ? singleNew.title_ru : singleNew.title_en}
          // breadCrumb={
          //   location === "uz" ? singleNew.title_uz : singleNew.title_ru
          // }
        />
        <div className="container mx-auto">
          <div className="box py-5 news_details">
            <div className="images w-full gap-10 flex">
              <div className="right w-full">
                <Swiper
                  direction={"horizontal"}
                  pagination={{
                    clickable: true,
                  }}
                  coverflowEffect={{
                    rotate: 50,
                    stretch: 0,
                    depth: 100,
                    modifier: 1,
                    slideShadows: true,
                  }}
                  effect={"coverflow"}
                  grabCursor={true}
                  centeredSlides={true}
                  spaceBetween={5}
                  modules={[Pagination, EffectCoverflow, Navigation]}
                  navigation={true}
                  slidesPerView={"auto"}
                  className="mySwiper"
                >
                  {singleNew &&
                    singleNew?.files?.map((video) => (
                      <SwiperSlide key={video.id}>
                        <iframe
                          title="video"
                          src={`${video?.video_url}`}
                          width={"100%"}
                          height={"100%"}
                          style={{
                            height: "230px",
                            width: "100%",
                            objectFit: "cover",
                          }}
                          frameBorder={0}
                          allowFullScreen={true}
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        />
                      </SwiperSlide>
                    ))}
                </Swiper>
              </div>
            </div>
            <div
              className="content py-5 rounded-lg"
              style={{ boxShadow: "0 0 20px 0 rgb(24 50 67 / 12%)" }}
            >
              <div className="p-4">
                {location === "uz"
                  ? parse(`${content_uz}`)
                  : location === "ru" ? parse(`${content_ru}`)
                  : parse(`${content_en}`)}
                <h1 className="font-bold mt-5 text-end">
                  {moment(singleNew.created_at).format("DD-MM-YYYY HH:mm")}
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default VideoDetails;

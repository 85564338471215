import React, { useEffect, useState } from "react";
import CommonForm from "./Layout/CommonForm";
import CommonSection from "./Layout/CommonSection";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { getSingleProducts, sendMessage } from "../redux/actions/actions";
import { useParams, useNavigate } from "react-router-dom";

export default function UseServices() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { product } = useSelector((state) => state.products);
  const { location } = useSelector((state) => state.location);

  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone_number, setPhoneNumber] = useState("");
  const [message, setMessage] = useState("");
  const [formFile, setFormFile] = useState(null);
  const [fileName, setFileName] = useState("");

  const changeFile = (e) => {
    let file = e.target.files[0];
    let formData = new FormData();
    formData.append("file", file);
    for (let key of formData.entries()) {
      console.log(key + ", " + key);
    }
    setFormFile(file);
    setFileName(file.name);
  };

  useEffect(() => {
    const fetchSingleService = async () => {
      await dispatch(getSingleProducts(id));
    };
    fetchSingleService();
  }, [id]);

  useEffect(() => window.scrollTo(0, 0), []);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!name) {
      toast.error("Ismingiz yoki tashkilot nomini kiritmadingiz!");
    } else if (!email) {
      toast.error("Pochtangizni kiritmadingiz!");
    } else if (!phone_number) {
      toast.error("Telefon raqamingizni kiritmadingiz!");
    } else if (!message) {
      toast.error("Xabarni kiritmadingiz!");
    } else if (!name) {
      toast.error("Kerakli hujjat tanlamadizgiz!");
    } else {
      const formData = new FormData();
      formData.append("name", name);
      formData.append("email", email);
      formData.append("phone_number", phone_number);
      formData.append("message", message);
      formData.append("services", Number(id));
      formData.append("file", formFile);

      const formData1 = new FormData();
      formData1.append("name", name);
      formData1.append("email", email);
      formData1.append("phone_number", phone_number);
      formData1.append("message", message);
      formData1.append("services", Number(id));
      try {
        dispatch(sendMessage(formFile ? formData : formData1));
        toast.success("Arizangiz qabul qilindi tez orada bo'glanamiz");
        setName("");
        setEmail("");
        setMessage("");
        setPhoneNumber("");
        setFormFile("");
        setFileName("");
        navigate("/");
      } catch (e) {
        console.log(e);
        toast.error(e.message);
      }
    }
  };

  return (
    <>
      <div>
        <CommonSection
          title={location === "uz" ? product.name_uz : location === "ru" ? product.name_ru : product.name_en}
          breadCrumb={location === "uz" ? product.name_uz : product.name_ru}
        />

        <div className="container mx-auto pt-2">
          {/* <p className='mb-16 ml-2'>Unicon data uz</p> */}
          <h2 className="text-logo mb-12 text-4xl font-medium text-blue-900">
            {location === "uz" ? product.name_uz : location === "ru" ? product.name_ru : product.name_en}
          </h2>

          <CommonForm
            name={name}
            email={email}
            phone={phone_number}
            message={message}
            file={formFile}
            fileChange={(e) => changeFile(e)}
            changeName={setName}
            changeEmail={setEmail}
            changePhoneNumber={setPhoneNumber}
            changeMessage={setMessage}
            submit={handleSubmit}
            fileName={fileName}
          />
        </div>
      </div>
    </>
  );
}

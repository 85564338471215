import React, { useState } from "react";
import CommonSection from "../../components/Layout/CommonSection";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { getCorruption } from "../../redux/actions/actions";
import { useTranslation } from "react-i18next";
import Image from "../../assets/no-results-bg.2d2c6ee3.png";

export default function AntiCorruption() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const location1 = useLocation();

  const year = new Date().getFullYear();

  const [stateYear, setStateYear] = useState(year.toString());

  const [openIndex, setOpenIndex] = useState(null);

  const { location } = useSelector((state) => state.location);
  const { corruption } = useSelector((state) => state.corruption);

  useEffect(() => {
    dispatch(
      getCorruption(location1.pathname === "/anti-korrupsiya" ? "LR" : "REP")
    );
  }, [location1, dispatch]);

  const handleToggle = (index) => {
    setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const filteredData = corruption?.filter(
    (item) => item?.year.toString() === stateYear
  );

  return (
    <section className="">
      <CommonSection
        title={
          location1.pathname === "/anti-korrupsiya"
            ? (location === "uz" ? "Korrupsiyaga qarshi kurashish bo'yicha idoraviy xujjatlar" : location === "ru" ? "Ведомственные документы по противодействию коррупции" : "Departmental documents on fighting corruption")
            : (location === "uz" ? "Hisobotlar" : location === "ru" ? "Отчеты" : "Reports")
        }
      />

      <div className="container mx-auto py-5">
        {location1.pathname === "/hisobotlar" && (
          <div className="tabs flex items-center gap-5 justify-center mb-4">
            <h1
              onClick={() => setStateYear("2023")}
              className={`py-2 px-5 border border-logo rounded cursor-pointer duration-300 ${
                stateYear === "2023" ? "bg-logo text-white" : ""
              }`}
            >
              2023 {t("year")}
            </h1>
            <h1
              onClick={() => setStateYear("2024")}
              className={`py-2 px-5 border border-logo rounded cursor-pointer duration-300 ${
                stateYear === "2024" ? "bg-logo text-white" : ""
              }`}
            >
              2024 {t("year")}
            </h1>
            {/*<h1*/}
            {/*  onClick={() => setStateYear('2025')}*/}
            {/*  className={`py-2 px-5 border border-logo rounded cursor-pointer duration-300 ${stateYear === '2025' ? 'bg-logo' +*/}
            {/*    ' text-white' : ''}`}*/}
            {/*>*/}
            {/*  2025 {t('year')}*/}
            {/*</h1>*/}
          </div>
        )}
        {location1.pathname !== "/hisobotlar" &&
          corruption &&
          corruption?.map((item, index) => (
            <div key={index} onClick={() => handleToggle(index)}>
              <div
                className={`px-2 py-4 mt-2 cursor-pointer flex items-center justify-between ${
                  openIndex === index
                    ? "bg-logo text-white"
                    : "bg-accordion text-dark"
                } duration-200`}
              >
                <div
                  className={""}
                  dangerouslySetInnerHTML={{
                    __html: location === "uz" ? item?.name_uz : location === "ru" ? item?.name_ru : item?.name_en,
                  }}
                />
                {openIndex === index ? (
                  <h1 className={"text-3xl"}>-</h1>
                ) : (
                  <h1 className={"text-3xl"}>+</h1>
                )}
              </div>
              {openIndex === index && (
                <div
                  className={
                    "bg-accordion_content px-4 py-2 w-[99%] m-auto flex flex-col gap-2"
                  }
                >
                  <div
                    className={""}
                    dangerouslySetInnerHTML={{
                      __html:
                        location === "uz" ? item?.content_uz : location === "ru" ? item?.content_ru : item?.content_en,
                    }}
                  />
                  <a
                    rel="noreferrer"
                    title="yuklash"
                    className="ml-auto px-4 py-2 mt-3 rounded-[4px] bg-[#0E0E4B] text-white xs:px-2 xs:py-1 justify-center duration-200 opacity-70 hover:opacity-100"
                    href={item.file}
                    target={"_blank"}
                  >
                    {t("download")}
                  </a>
                </div>
              )}
            </div>
          ))}
        {filteredData.length === 0 &&
        location1.pathname !== "/anti-korrupsiya" ? (
          <div className={"mx-auto w-full text-center"}>
            <img src={Image} alt="" className={"w-1/4 mx-auto"} />
            <h1 className={"text-3xl"}>{t("soon")}</h1>
          </div>
        ) : (
          location1.pathname === "/hisobotlar" &&
          filteredData &&
          filteredData?.map((item, index) => (
            <div key={index} onClick={() => handleToggle(index)}>
              <div
                className={`px-2 py-4 mt-2 cursor-pointer flex items-center justify-between ${
                  openIndex === index
                    ? "bg-logo text-white"
                    : "bg-accordion text-dark"
                } duration-200`}
              >
                <div
                  className={""}
                  dangerouslySetInnerHTML={{
                    __html: location === "uz" ? item?.name_uz : location === "ru" ? item?.name_ru : item?.name_en,
                  }}
                />
                {openIndex === index ? (
                  <h1 className={"text-3xl"}>-</h1>
                ) : (
                  <h1 className={"text-3xl"}>+</h1>
                )}
              </div>
              {openIndex === index && (
                <div
                  className={
                    "bg-accordion_content px-4 py-2 w-[99%] m-auto flex flex-col gap-2"
                  }
                >
                  <div
                    className={""}
                    dangerouslySetInnerHTML={{
                      __html:
                        location === "uz" ? item?.content_uz : location === "ru" ? item?.content_ru : item?.content_en,
                    }}
                  />
                  <a
                    rel="noreferrer"
                    title="yuklash"
                    className="ml-auto px-4 py-2 mt-3 rounded-[4px] bg-[#0E0E4B] text-white xs:px-2 xs:py-1 justify-center duration-200 opacity-70 hover:opacity-100"
                    href={item.file}
                    target={"_blank"}
                  >
                    {t("download")}
                  </a>
                </div>
              )}
            </div>
          ))
        )}
      </div>
    </section>
  );
}

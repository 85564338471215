import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getSingleProducts } from "../redux/actions/actions";
import CommonSection from "./Layout/CommonSection";
import parse from "html-react-parser";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function ProductsDetails() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { product } = useSelector((state) => state.products);
  const { content_uz, content_ru, content_en } = useSelector(
    (state) => state.products.product
  );
  const { location } = useSelector((state) => state.location);
  const { t } = useTranslation();

  useEffect(() => {
    const fetchSingleService = async () => {
      await dispatch(getSingleProducts(id));
    };
    fetchSingleService();
  }, [id]);

  useEffect(() => window.scrollTo(0, 0), []);

  return (
    <>
      <div>
        <CommonSection
          title={location === "uz" ? product.name_uz : location === 'ru' ? product.name_ru : product.name_en}
          // breadCrumb={location === "uz" ? product.name_uz : product.name_ru}
        />
        <div className="container mx-auto py-7">
          {location === "uz" ? parse(`${content_uz}`) : location === 'ru' ? parse(`${content_ru}`) : parse(`${content_en}`)}
          <Link
            to={`/useServices/${product.id}`}
            className="flex rounded-3xl items-center gap-2 px-2 py-2 mt-5 bg-[#0E0E4B] text-white w-1/4 md:w-[32%] xs:w-1/4 xs:px-2 xs:py-1 justify-center duration-200 hover:bg-[#00ce87]"
          >
            {t("ariza")}
          </Link>
        </div>
      </div>
    </>
  );
}

export default ProductsDetails;

import React from 'react';
import {Link} from "react-router-dom";

function CommonSection({title, breadCrumb}) {
    return (
        <div className="common__section w-full flex flex-wrap text-center justify-center items-center pb-20 pt-72 lg:pt-28">
            <div className="container mx-auto text-center">
                <h1 className='text-white font-semibold relative z-10 uppercase text-5xl md:text-2xl common_title'>{title}</h1>
                {/* <div className="breadCrumb flex items-center gap-2 justify-center text-white relative z-10 mt-5 sm:flex-wrap sm:gap-1">
                    <Link to='/' className='uppercase xs:text-sm'>unicon data uz</Link>
                    &gt;
                    <h1 className='text-base uppercase xs:text-sm'>{breadCrumb}</h1>
                </div> */}
            </div>
        </div>
    );
}

export default CommonSection;

import React from 'react';
import Eri from "../../assets/eri.png"


const EriModal = ({onclose}) => {
  return (
    <div
      className="fixed top-0 left-0 right-0 bottom-0 p-12 opacity-100 bg-[rgba(0,0,0,0.5)] flex flex-col items-center justify-center z-[999]"
    >
      <div
        className="rounded w-[99%] max-h-[100vh] p-12 flex flex-col items-center justify-center gap-4 overflow-auto bg-white"
      >
        <div className="max-h-[70vh] w-full">
          <div className={`requirement-wrapper-scroll`}>
            <h3 className='requirement-title-main font-bold'>
              Eri kalitlarini ro'yxatga olish markazi
            </h3>

            <p >
              O'zbekiston Respublikasi milliy standartlari asosida ishlab chiqilgan hamda o'rnatilgan tartibda sertifikatsiyadan o'tkazilgan eri kalitlarini ro'yxatga olish markazi tizimlari
              Eri kalitlarini ro'yxatga olish markazi tizimlari O'zbekiston Respublikasining "elektron raqamli imzo to'g'risida"gi Qonuniga muvofiq quyidagi vazifalarni bajaradi:
            </p>
            <p >
              Eri kalitlarini generasiya qilish;
            </p>
            <p className='requirement-text'>
              Eri ochiq kaliti sertifikatlarini berish;
            </p>
            <p className='requirement-text'>
              Sertifikatlarni to'xtatib turish, qayta tiklash va bekor qilish.
            </p>
            <p className='requirement-text'>
              Davlat idoralari va tashkilotlar, tijorat banklari o'zlarining axborot tizimlarida eridan foydalanish uchun xodimlariga va mijozlariga eri kalitlari sertifikatlarini berish uchun xarid qilishadi.
            </p>

            <img width={800} src={Eri} alt="" />
            

            <button
              className="px-4 py-2 my-4 rounded-[4px] bg-[#0E0E4B] text-white xs:px-2 xs:py-1 duration-200 opacity-70 hover:opacity-100"
              onClick={onclose}
            >
              OK
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EriModal;
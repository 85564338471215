import React, {Suspense} from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {BrowserRouter} from "react-router-dom";
import {ToastContainer} from "react-toastify";
import {Provider} from "react-redux";
import store from "./redux/store";
import {initReactI18next} from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from 'i18next-http-backend'
import "react-toastify/dist/ReactToastify.css";
import "remixicon/fonts/remixicon.css";
import 'swiper/css';
import 'aos/dist/aos.css';
import i18next from "i18next";
import EditTextModal from './components/modal/editTextModal';

i18next
.use(initReactI18next)
.use(LanguageDetector)
.use(HttpApi)
.init({
  supportedLngs: ['ru', 'uz', "en"],
  fallbackLng: "uz",
  detection: {
    order: ['path', 'cookie', 'htmlTag', 'localStorage', 'subdomain'],
    caches: ['localStorage']
  },
  backend: {
    loadPath: '/assets/Locales/{{lng}}/translation.json',
  },
  react: {useSuspense: false}
});

const loading = (
  <div className="spinner-border" role="status">
    <span className="visually-hidden">Loading...</span>
  </div>
)

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Suspense fallback={loading}>
    <BrowserRouter>
      <Provider store={store}>
        <App/>
        <ToastContainer/>
      </Provider>
    </BrowserRouter>
  </Suspense>
);

import { NavLink, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useState, useEffect, useRef } from "react";
import Dropdown from "./Dropdown";

const MenuItems = ({ items, depthLevel }) => {
	const [dropdown, setDropdown] = useState(false);
	const navigate = useNavigate();

	let ref = useRef();

	useEffect(() => {
		const handler = (event) => {
			if (dropdown && ref.current && !ref.current.contains(event.target)) {
				setDropdown(false);
			}
		};
		document.addEventListener("mousedown", handler);
		document.addEventListener("touchstart", handler);
		return () => {
			// Cleanup the event listener
			document.removeEventListener("mousedown", handler);
			document.removeEventListener("touchstart", handler);
		};
	}, [dropdown]);

	const onMouseEnter = () => {
		window.innerWidth > 960 && setDropdown(true);
	};

	const onMouseLeave = () => {
		window.innerWidth > 960 && setDropdown(false);
	};

	const closeDropdown = () => {
		dropdown && setDropdown(false);
	};

	const { t } = useTranslation();
	const { location } = useSelector((state) => state.location);

	return (
		<li
			className="menu-items lg:text-xs p-1 lg:bg-bg-calc lg:w-full lg:mb-1 rounded-md"
			ref={ref}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
			onClick={closeDropdown}
		>
			{items.path && items.children ? (
				<>
					<button
						className="font-bold uppercase nav_link flex gap-2 items-center"
						type="button"
						aria-haspopup="menu"
						aria-expanded={dropdown ? "true" : "false"}
						onClick={() => {
							setDropdown((prev) => !prev);
							if (items?.path) {
								navigate(items?.path);
							}
						}}
					>
						{window.innerWidth < 960 && depthLevel === 0 ? (
							<>{items[location]}</>
						) : (
							<h1>{items[location]}</h1>
						)}

						{depthLevel > 0 ? <span>&raquo;</span> : <span className="arrow" />}
					</button>
					<Dropdown
						depthLevel={depthLevel}
						submenus={items.children}
						dropdown={dropdown}
					/>
				</>
			) : !items.path && items.children ? (
				<>
					<button
						type="button"
						aria-haspopup="menu"
						className="font-bold uppercase nav_link"
						aria-expanded={dropdown ? "true" : "false"}
						onClick={() => setDropdown((prev) => !prev)}
					>
						{items[location]}{" "}
						{depthLevel > 0 ? <span>&raquo;</span> : <span className="arrow" />}
					</button>
					<Dropdown
						depthLevel={depthLevel}
						submenus={items.children}
						dropdown={dropdown}
					/>
				</>
			) : items.href ? (
				<a
					className="uppercase font-bold nav_link"
					target="_blank"
					title={
						location === "uz"
							? items.title_uz
							: location === "ru"
							? items.title_ru
							: items.title_en
					}
					href={items.href}
					rel="noreferrer"
				>
					{items[location]}
				</a>
			) : (
				<NavLink className="uppercase font-bold nav_link" to={items.path}>
					{items[location]}
				</NavLink>
			)}
		</li>
	);
};

export default MenuItems;

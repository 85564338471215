import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { getNews } from "../../redux/actions/actions";
import CommonSection from "../../components/Layout/CommonSection";
import BgVd from "../../assets/bgVd.mp4";

function Tarix() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { location } = useSelector((state) => state.location);
  const { news } = useSelector((state) => state.news);

  const type = "yangiliklar?last_count=10";

  useEffect(() => {
    dispatch(getNews({ type }));
  }, []);

  const [state, setState] = useState(false);

  useEffect(() => window.scrollTo(0, 0), []);

  return (
    <>
      <div className="history">
        <CommonSection title={t("history")} breadCrumb={t("history")} />

        <div className={"container flex mx-auto py-6"}>
          <div className="w-1/4 pr-5 lg:hidden">
            <div className="sticky top-0">
              <h3 className="text-2xl font-medium text-logo mb-8">
                {t("news")}
              </h3>

              <div>
                {news &&
                  news?.result?.map((item, idx) => (
                    <Link
                      id={idx}
                      className="flex min-h-[90px] pb-5 border-b border-[#ccc] mb-5"
                      to={`/news/${item.id}`}
                      onClick={() => window.scrollTo(0, 0)}
                    >
                      {item?.files[0]?.is_video ? (
                        <iframe
                          className="object-cover block h-[70px]"
                          title="video"
                          src={`${item?.files[0]?.video_url}`}
                          width={"100%"}
                          height={"100%"}
                          style={{
                            height: "70px",
                            width: "70px",
                            objectFit: "cover",
                          }}
                          frameBorder={0}
                          allowFullScreen={true}
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        />
                      ) : (
                        <img
                          className="object-cover block h-[70px]"
                          width={"70"}
                          height={"70"}
                          src={`${item?.files[0]?.file}`}
                          alt="rasm"
                        />
                      )}
                      <div className="pl-4">
                        <p className="text-logo hover:text-hover text-xs uppercase mb-2">
                          {location === "uz"
                            ? item?.title_uz?.substring(0, 70)
                            : location === "ru"
                            ? item?.title_ru?.substring(0, 70)
                            : item?.title_en?.substring(0, 70)}
                        </p>
                        <div
                          className="text-logo-secondary hover:text-hover text-xs"
                          dangerouslySetInnerHTML={{
                            __html:
                              location === "uz"
                                ? item?.content_uz?.substring(3, 70)
                                : location === "ru"
                                ? item?.content_ru?.substring(3, 70)
                                : item?.content_en?.substring(3, 70),
                          }}
                        />
                      </div>
                    </Link>
                  ))}
              </div>
            </div>
          </div>

          <div
            className="card w-3/4 lg:w-full"
            style={{ border: "1px solid #abb8c3" }}
          >
            <div className="w-full">
              <div
                className="videorolik videorolik--tarix relative cursor-pointer"
                onClick={() => setState(true)}
              >
                <video
                  className="videoUnicon videoUnicon--tarix absolute w-full object-cover"
                  src={BgVd}
                  loop={true}
                  autoPlay={true}
                  muted
                />

                <div className="videorolik__link cursor-pointer">
                  <div className="round1">
                    <div className="round2">
                      <svg
                        width="180"
                        height="180"
                        viewBox="0 0 212 212"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M66.9124 50.4207C65.0896 68.8757 64.1993 87.411 64.2447 105.956C64.2447 130.698 65.7464 150.096 66.9124 161.509C83.8032 153.841 100.298 145.33 116.335 136.007C132.422 126.782 148.035 116.756 163.116 105.965C148.037 95.1643 132.427 85.1258 116.344 75.8872C100.302 66.5784 83.8044 58.0789 66.9124 50.4207ZM50.0849 42.0467C50.3217 40.0401 51.0238 38.1166 52.1352 36.4293C53.2467 34.7421 54.7368 33.3376 56.4869 32.3279C58.2369 31.3182 60.1986 30.7311 62.2156 30.6134C64.2326 30.4956 66.2493 30.8506 68.1049 31.6498C77.4859 35.6602 98.5092 45.1913 125.186 60.5878C151.871 75.9932 170.642 89.4463 178.795 95.5502C185.756 100.771 185.774 111.123 178.804 116.362C170.731 122.43 152.189 135.706 125.186 151.306C98.1559 166.906 77.3799 176.322 68.0872 180.279C60.0842 183.698 51.1272 178.513 50.0849 169.883C48.8659 159.795 46.5869 136.89 46.5869 105.956C46.5869 75.0392 48.8571 52.1432 50.0849 42.0467Z"
                          fill="white"
                          fillOpacity="0.95"
                        />
                      </svg>
                    </div>
                  </div>
                </div>

                <h2 className="videorolik__title uppercase">
                  {location === "uz"
                    ? '"UNICON.UZ" mchj haqida videorolik'
                    : 'ВИДЕОРОЛИК О ООО "UNICON.UZ"'}
                </h2>
              </div>

              {state && (
                <div
                  className="video-wrap"
                  onClick={() => setState(false)}
                  style={{
                    display: state ? "flex" : "",
                  }}
                >
                  <div className="video-wrap__modal">
                    <iframe
                      width="100%"
                      height={500}
                      src="https://www.youtube.com/embed/jhWHFpNff2M?si=dbugnLAkvj_ddYmF"
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowfullscreen
                    ></iframe>
                  </div>
                </div>
              )}
            </div>

            <div className="py-8 px-16 xl:p-8">
              <div className="stm_markup__content flex flex-col gap-5">
                <p>
                  <strong>{t("y-1992")}</strong> {t("y-1992-text")}
                </p>
                <p>{t("y-1992-info")}</p>
                <p>
                  <strong>{t("y-1994-1996")}</strong> {t("y-1994-1996-text")}
                </p>
                <p>
                  <strong>{t("y-1995")}</strong> {t("y-1995-text")}
                </p>
                <p>
                  <strong>{t("y-1997")}</strong> {t("y-1997-text")}
                </p>
                <p>
                  <strong>{t("y-1997-a")}</strong> {t("y-1997-a-text")}
                </p>
                <p>
                  <strong>{t("y-1998")}</strong> {t("y-1998-text")}
                </p>
                <p>
                  <strong>{t("y-1998")}</strong> {t("y-1998-text-FTMTM")}
                </p>
                <p>
                  <strong>{t("y-1999")}</strong> {t("y-1999-text")}
                </p>
                <p>
                  <strong>{t("y-2001")}</strong> {t("y-2001-text")}
                </p>
                <p>
                  <strong>{t("y-2002-2004")}</strong> {t("y-2002-2004-text")}
                </p>
                <p>
                  <strong>{t("y-2002")}</strong> {t("y-2002-text")}
                </p>
                <p>
                  <strong>{t("y-2005")}</strong>
                  <br />
                  {t("y-2005-text")}
                </p>
                <p>
                  <strong>{t("y-2006")}</strong> {t("y-2006-text")}
                </p>
                <p>
                  <strong>2007 {t("year")}</strong> {t("y-2007-text")}
                </p>
                <p>
                  <strong>
                    {t("y-2009")}
                    <br />
                  </strong>{" "}
                  {t("y-2009-text")} <br />
                  {t("y-2009-text2")}
                  <br />
                  {t("y-2009-text3")}
                  <br />
                  {t("y-2009-text4")}
                </p>
                <p>
                  <strong>2011 {t("year")}</strong> {t("y-2011-text")}
                </p>
                <p>
                  <strong>2013 {t("year")}</strong> {t("y-2013-text")}
                </p>
                <p>
                  <strong>2015 {t("year")}</strong> {t("y-2015-text")}
                </p>
                <p>
                  <strong>2017 {t("year")}</strong> {t("y-2017-text")}
                </p>
                <p>
                  <strong>2018 {t("year")}</strong> {t("y-2018-text")}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Tarix;

import React, { useEffect, useState } from "react";
import CommonSection from "../../components/Layout/CommonSection";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { getNews } from "../../redux/actions/actions";
import moment from "moment";
import Image from "../../assets/no-results-bg.2d2c6ee3.png";
import Pagination from "../../components/Layout/Pagination";

function Videos() {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const type = pathname?.substring(1, 13);
  const { news, loading } = useSelector((state) => state.news);
  const { location } = useSelector((state) => state.location);
  const { t } = useTranslation();

  const [hoveredItemId, setHoveredItemId] = useState(null);

  useEffect(() => {
    dispatch(getNews({ type }));
  }, [pathname]);

  useEffect(() => window.scrollTo(0, 0), []);

  const handlePageChange = (page) => {
    dispatch(getNews({ type, page }));
  };

  if (loading) return "Loading...";

  return (
    <>
      <div className="news">
        <CommonSection title={t("video")} breadCrumb={t("video")} />
        <div className="container mx-auto">
          <div className="box py-7 flex flex-wrap gap-10 md:gap-12 md:justify-between sm:gap-0">
            {news?.result?.length === 0 ? (
              <div className={"mx-auto w-full text-center"}>
                <img src={Image} alt="" className={"w-1/4 mx-auto"} />
                <h1 className={"text-3xl"}>{t("soon")}</h1>
              </div>
            ) : (
              news?.result?.map((item, idx) => {
                return (
                  <Link
                    to={`/video/${item.id}`}
                    key={idx}
                    onMouseOver={() => setHoveredItemId(item.id)}
                    onMouseLeave={() => setHoveredItemId(null)}
                    className="relative products w-[31%] min-h-full md:w-[45%] h-full"
                  >
                    <div className="flex flex-col gap-1 w-full justify-between min-h-full">
                      <div className="w-full overflow-hidden">
                        <div className="flex gap-2">
                          <span>
                            <i className="ri-time-line"></i>
                          </span>
                          <h1>
                            {moment(item.created_at).format("DD-MM-YYYY HH:mm")}
                          </h1>
                          <div className="flex gap-2 ml-auto">
                            <span>
                              <i className="ri-eye-line"></i>
                            </span>
                            {item.views_count}
                          </div>
                        </div>
                        {item?.files[0]?.is_video ? (
                          <iframe
                            title="video"
                            src={`${item?.files[0]?.video_url}`}
                            width={"100%"}
                            height={"100%"}
                            style={{
                              height: "230px",
                              width: "100%",
                              objectFit: "cover",
                            }}
                            frameBorder={0}
                            allowFullScreen={true}
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          />
                        ) : (
                          <img
                            className="w-full h-60 object-cover"
                            src={`${item?.files[0]?.file}`}
                            alt="img"
                          />
                        )}
                      </div>
                      <h1>{`${
                        location === "uz"
                          ? item.title_uz?.slice(0, 35)
                          : location === "ru" ? item.title_ru?.slice(0, 35)
                          : item.title_en?.slice(0, 35)
                      }...`}</h1>
                      <div
                        className={`${
                          hoveredItemId === item.id
                            ? "bg-white bottom-0 left-0 opacity-100 pointer-events-auto"
                            : "opacity-0" + " pointer-events-none -bottom-12"
                        } absolute w-full h-2/4 p-2 duration-300`}
                      >
                        <h1 className={"mb-2 font-bold"}>{`${
                          location === "uz" ? item.title_uz : location === "ru" ? item.title_ru : item.title_en
                        }`}</h1>
                        <div
                          dangerouslySetInnerHTML={{
                            __html:
                              location === "uz"
                                ? `${item.content_uz?.substring(3, 70)}...`
                                : location === "ru" ? `${item.content_ru?.substring(3, 70)}...`
                                : `${item.content_en?.substring(3, 70)}...`,
                          }}
                        />
                      </div>
                      {/* <div className="relative py-2 flex justify-between items-center">
                        <Link
                          to={`/video/${item.id}`}
                          className="relative py-2 w-2/12 news-link"
                        >
                          {t("more")}
                        </Link>
                        <div className="flex gap-2">
                            <span>
                              <i className="ri-eye-line"></i>
                            </span>
                          {item.views_count}
                        </div>
                      </div> */}
                    </div>
                  </Link>
                );
              })
            )}
          </div>
          <div className={"flex justify-center pb-3"}>
            {news?.result?.length === 0 ? (
              ""
            ) : (
              <Pagination
                totalItems={news?.count}
                itemsPerPage={12}
                onPageChange={handlePageChange}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Videos;

import React from 'react';
import First from "../../assets/2024-11-11_16-15-24.png"
import Second from "../../assets/2024-11-11_16-18-41.png"
import Third from "../../assets/2024-11-11_16-19-09.png"

const UzDocModal = ({onclose}) => {
  return (
    <div
      className="fixed top-0 left-0 right-0 bottom-0 p-12 opacity-100 bg-[rgba(0,0,0,0.5)] flex flex-col items-center justify-center z-[999]"
    >
      <div
        className="rounded w-[99%] max-h-[100vh] p-12 flex flex-col items-center justify-center gap-4 overflow-auto bg-white"
      >
        <div className="max-h-[70vh] w-full">
          <div className={`requirement-wrapper-scroll`}>
            <h3 className='requirement-title-main font-bold'>
              “UzDoc” “dasturiy ta’minoti
            </h3>

            <p className='requirement-text'>
              1) ERIlar orqali bir hujjatni bir yoki bir nechta foydalanuvchi tomonidan imzolash
            </p>
            <p className='requirement-text'>
              2) ERIdagi shakllantirilgan ma’lumotlar asosida hujjatlarning to‘liqligi, yaxlitligi va maxfiyligini ta’minlash
            </p>
            <p className='requirement-text'>
              3) huquqiy ahamiyatini saqlash
            </p>
            <p className='requirement-text'>
              4) maxsus “UZDOC” formati asosida yaratilgan hujjat asosida barcha turdagi algoritmlardan tashkil topgan ERIlarni qo‘llash
            </p>
            <p className='requirement-text'>
              5) ERI kaliti sertifikati bo‘yicha to‘liq ma’lumotlarni saqlash
            </p>

            <img width={800} src={First} alt="" />
            <img width={800} src={Second} alt="" />
            <img width={800} src={Third} alt="" />
            

            <button
              className="px-4 py-2 my-4 rounded-[4px] bg-[#0E0E4B] text-white xs:px-2 xs:py-1 duration-200 opacity-70 hover:opacity-100"
              onClick={onclose}
            >
              OK
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UzDocModal;
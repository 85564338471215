import {Link, useNavigate} from 'react-router-dom'
import {useEffect} from "react";

const PageNotFound = () => {

  const navigate = useNavigate();

  // setTimeout(() => {
  //   navigate('/')
  // }, 1000);
  useEffect(() => window.scrollTo(0,0), [])

  return (
    <section className='container mx-auto h-[calc(100vh-230px)]'>

      <div className='flex flex-col justify-center items-center gap-y-8 h-full pb-28'>

        <p className='text-violet-700 font-semibold text-4xl'>404 - Page Not Found!</p>

        <Link to='/' className='text-gray-800 text-2xl hover:underline'>Back To Home</Link>

      </div>

    </section>
  )
}

export default PageNotFound

import {
  GET_NAVBAR,
  GET_SINGLE_SERVICES,
  SEND_MESSAGE,
  GET_PRODUCTS,
  GET_SINGLE_PRODUCTS,
  GET_MEMBERS,
  SWITCH_LANG,
  GET_RESOURCE,
  GET_SINGLE_RESOURCE,
  GET_NEWS,
  GET_SINGLE_NEWS,
  GET_STATISTICS,
  GET_PARTNERS,
  GET_VIDEOS,
  GET_VIDEO_SINGLE,
  GET_VACANCY,
  GET_CORRUPTION,
  GET_DOCS,
  GET_PURCHES,
  GET_HERO_VIDEO,
  GET_APPEAL,
} from "../constants/constants";

const initialStateBolimlar = {
  bolimlar: [],
  bolim: {},
  loading: false,
};

const initialStateNews = {
  news: [],
  singleNew: {},
  loading: false,
};

const initialStateVideos = {
  videos: [],
  video: {},
  loading: false,
};

const initialStateStatistics = {
  statistics: [],
  loading: false,
};

const initialStatePartners = {
  partners: [],
  loading: false,
};

const initialStateProducts = {
  products: [],
  product: {},
  loading: false,
};

const initialStateMember = {
  members: [],
  loading: false,
};

const initialStateResource = {
  resources: [],
  resource: {},
  loading: false,
};

const lang = {
  location: localStorage.getItem("i18nextLng")
    ? localStorage.getItem("i18nextLng")
    : "uz",
};

const initialStateVacancies = {
  vacancies: [],
  loading: false,
};

const initialStateCorrupt = {
  corruption: [],
  loading: false,
};

const initialStateAppeal = {
  appeal: [],
  loading: false,
};

const initialStateDocs = {
  docs: [],
  loading: false,
};

const initialStatePurches = {
  purches: [],
  loading: false,
};
const initialStateHero = {
  hero: [],
  loading: false,
};

export const navbarReducer = (state = initialStateBolimlar, action) => {
  switch (action.type) {
    case GET_NAVBAR:
      return {
        ...state,
        bolimlar: action.payload,
        loading: false,
      };
    case GET_SINGLE_SERVICES:
      return {
        ...state,
        bolim: action.payload,
        loading: false,
      };
    case SEND_MESSAGE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const productsReducer = (state = initialStateProducts, action) => {
  switch (action.type) {
    case GET_PRODUCTS:
      return {
        ...state,
        products: action.payload,
        loading: false,
      };
    case GET_SINGLE_PRODUCTS:
      return {
        ...state,
        product: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

export const membersReducer = (state = initialStateMember, action) => {
  switch (action.type) {
    case GET_MEMBERS:
      return {
        ...state,
        members: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

export const statisticsReducer = (state = initialStateStatistics, action) => {
  switch (action.type) {
    case GET_STATISTICS:
      return {
        ...state,
        statistics: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

export const partnersReducer = (state = initialStatePartners, action) => {
  switch (action.type) {
    case GET_PARTNERS:
      return {
        ...state,
        partners: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

export const recourseReducer = (state = initialStateResource, action) => {
  switch (action.type) {
    case GET_RESOURCE:
      return {
        ...state,
        resources: action.payload,
        loading: false,
      };
    case GET_SINGLE_RESOURCE:
      return {
        ...state,
        resource: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

export const newsReducer = (state = initialStateNews, action) => {
  switch (action.type) {
    case GET_NEWS:
      return {
        ...state,
        news: action.payload,
        loading: false,
      };
    case GET_SINGLE_NEWS:
      return {
        ...state,
        singleNew: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

export const videoReducer = (state = initialStateVideos, action) => {
  switch (action.type) {
    case GET_VIDEOS:
      return {
        ...state,
        videos: action.payload,
        loading: false,
      };
    case GET_VIDEO_SINGLE:
      return {
        ...state,
        video: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

export const langReducer = (state = lang, action) => {
  switch (action.type) {
    case SWITCH_LANG:
      return {
        ...state,
        location: action.payload,
      };
    default:
      return state;
  }
};

export const vacancyReducer = (state = initialStateVacancies, action) => {
  switch (action.type) {
    case GET_VACANCY:
      return {
        ...state,
        vacancies: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

export const corruptionReducer = (state = initialStateCorrupt, action) => {
  switch (action.type) {
    case GET_CORRUPTION:
      return {
        ...state,
        corruption: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

export const appealReducer = (state = initialStateAppeal, action) => {
  switch (action.type) {
    case GET_APPEAL:
      return {
        ...state,
        appeal: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

export const docsReducer = (state = initialStateDocs, action) => {
  switch (action.type) {
    case GET_DOCS:
      return {
        ...state,
        docs: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

export const purchesReducer = (state = initialStatePurches, action) => {
  switch (action.type) {
    case GET_PURCHES:
      return {
        ...state,
        purches: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

export const heroVideoReducer = (state = initialStateHero, action) => {
  switch (action.type) {
    case GET_HERO_VIDEO:
      return {
        ...state,
        hero: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

import React, { useEffect } from "react";
import CommonSection from "../../components/Layout/CommonSection";
import { useLocation } from "react-router-dom";
import { getMembers } from "../../redux/actions/actions";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Aos from "aos";

function IlmiyKengash() {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const type = pathname?.substring(1, 13);
  const { members } = useSelector((state) => state.members);
  const { location } = useSelector((state) => state.location);
  const { t } = useTranslation();

  useEffect(() => {
    const fetchMembers = async () => {
      await dispatch(getMembers(type));
    };
    fetchMembers();
    Aos.init();
  }, [pathname]);

  useEffect(() => window.scrollTo(0, 0), []);

  return (
    <>
      <div className="ilmiy-kengash">
        <CommonSection title={t("ilmiy")} breadCrumb={t("ilmiy")} />
        <div className="container mx-auto">
          <div className="box flex flex-wrap gap-6 py-6 md:justify-center">
            {members &&
              members?.map((item) => (
                <div key={item.id} className="w-full" data-aos="zoom-out-up" data-aos-duration={"1000"}>
                  <div
                    className="card w-full flex gap-10 items-center duration-300 md:flex-wrap md:items-start md:gap-2 md:p-2"
                    style={{ border: "1px solid #abb8c3" }}
                  >
                    <img
                      src={`${item.image}`}
                      className="h-[300px] w-[250px] object-cover md:w-full md:object-cover md:h-1/2 md:mt-0"
                      alt=""
                    />
                    <div className="content flex flex-col gap-3">
                      <h1 className="uppercase text-2xl text-logo-secondary">
                        {location === "uz"
                          ? item.full_name_uz
                          : location === "ru" ? item.full_name_ru
                          : item.full_name_en
                        }
                      </h1>
                      <p className="text-xl">
                        {location === "uz"
                          ? item.department_uz
                          : location === "ru" ? item.department_ru
                          : item.department_en}
                      </p>
                      <div className="">
                        <div className="flex gap-2 items-center hover:text-hover duration-200">
                          <span className="text-logo-secondary text-2xl">
                            <i className="ri-phone-fill"></i>
                          </span>
                          <a href={`tel:+${item.phone}`}>{item.phone}</a>
                        </div>
                        <div className="flex gap-2 items-center hover:text-hover duration-200">
                          <span className="text-logo-secondary text-2xl">
                            <i className="ri-mail-fill"></i>
                          </span>
                          <a href={`mailto:${item.email}`}>{item.email}</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default IlmiyKengash;

import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { getSingleResource } from "../redux/actions/actions";
import CommonSection from "./Layout/CommonSection";
import moment from "moment";

function ResourceDetails() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { resource } = useSelector((state) => state.recourses);
  const { location } = useSelector((state) => state.location);
  const { t } = useTranslation();

  useEffect(() => {
    const fetchSingleResource = async () => {
      await dispatch(getSingleResource(id));
    };
    fetchSingleResource();
  }, [id]);

  useEffect(() => window.scrollTo(0, 0), []);

  return (
    <>
      <div>
        <CommonSection
          title={
            resource[0]?.title_uz === undefined
              ? ""
              : location === "uz"
              ? resource[0]?.title_uz
              : location === "ru" ? resource[0]?.title_ru
              : resource[0]?.title_en
          }
          breadCrumb={
            resource[0]?.title_uz === undefined
              ? ""
              : location === "uz"
              ? resource[0]?.title_uz
              : location === "ru" ? resource[0]?.title_ru
              : resource[0]?.title_en
          }
        />
        <div className="container mx-auto">
          <div className="box py-5 flex flex-col gap-5">
            {resource[0]?.resources &&
              resource[0]?.resources?.map((item, idx) => (
                <div
                  key={item.id}
                  className="flex items-center justify-between py-2 px-3 text-center w-full"
                >
                  <h1 className="border border-grey p-7 w-1/4">
                    {location === "uz" ? item.name_uz : location === "ru" ? item.name_ru : item.name_en}
                  </h1>
                  <h1 className="border border-grey p-7 w-1/4">
                    {location === "uz"
                      ? item.short_desc_uz
                      : location === "ru" ? item.short_desc_ru
                      : item.short_desc_en}
                  </h1>
                  <h1 className="border border-grey p-7 w-1/4">
                    {moment(item.date).format("DD-MM-YYYY HH:mm")}
                  </h1>
                  <div className="border border-grey p-3.5 w-1/4">
                    <a
                      href={`https://back.unicon.uz${item.file}`}
                      target={"_blank"}
                      rel="noreferrer"
                      className="flex items-center gap-2 p-3 border border-logo rounded-lg hover:border-hover hover:bg-hover hover:text-white duration-300"
                    >
                      <span>
                        <i className="ri-download-line"></i>
                      </span>
                      {t("download")}
                    </a>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default ResourceDetails;

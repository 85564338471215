import React from 'react';
import Kriptomodul from "../../assets/kriptomodul.png"


const KriptoModal = ({onclose}) => {
  return (
    <div
      className="fixed top-0 left-0 right-0 bottom-0 p-12 opacity-100 bg-[rgba(0,0,0,0.5)] flex flex-col items-center justify-center z-[999]"
    >
      <div
        className="rounded max-w-[1000px] max-h-[100vh] p-12 pt-5 flex flex-col items-center justify-center gap-4 overflow-auto bg-white"
      >
        <button onClick={onclose} className='bg-bd px-3 py-1 rounded-full ml-auto mb-10' >X</button>

        <div className="max-h-[70vh] w-full">
          <div className={`requirement-wrapper-scroll`}>
            <h3 className='requirement-title-main font-bold'>
              Kriptomodul
            </h3>

            <p className='requirement-text'>
              Axborotni kriptografik himoyalash dasturiy moduli (Kriptomodul) Web Socket texnologiyasidan foydalangan holda brauzer bilan o'zaro aloqada bo'ladigan o'rnatiladigan dasturiy ta'minot (Windows, masos) ko'rinishida taqdim etiladi.
              Android va iOS mobil ilovalari uchun kutubxona sifatida taqdim etiladi.
            </p>
            <p className='requirement-text'>
              X.509 standart i asosida erilarni shakllantirish va tekshirish uchun server moduli.
            </p>
            <p className='requirement-text'>
              Shifrlash moduli – shifrlash algoritmi O'zDSt 1105 davlat standartiga mos keladi va shu standartga muvofiq ishlaydi.
              Ushbu modul veb interfeysli tizimlarda ishlatilishi mumkin: veb brauzerda Javascript orqali WebAssembly moduli ishlatiladi, serverda CLI interfeysi orqali kriptomodul funktsiyalarini ishlatish imkoni mavjud.
            </p>
            <p className='requirement-text'>
              Demonstrasiya paneliga havola: <a href="https://emicrypter.unicon.uz/">https://emicrypter.unicon.uz/</a>
            </p>

            <img width={800} src={Kriptomodul} alt="" />
            

            <button
              className="px-4 py-2 my-4 rounded-[4px] bg-[#0E0E4B] text-white xs:px-2 xs:py-1 duration-200 opacity-70 hover:opacity-100"
              onClick={onclose}
            >
              OK
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default KriptoModal;
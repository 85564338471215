import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getNews, getSingleServices } from "../redux/actions/actions";
import CommonSection from "./Layout/CommonSection";
import { ReactComponent as Phone } from "../assets/phone-number.svg";
import { ReactComponent as Mail } from "../assets/mail.svg";
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCoverflow, Navigation, Pagination } from "swiper";
import moment from "moment";

function BolimlarDetails() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id } = useParams();

  const { bolim } = useSelector((state) => state.bolimlar);
  const { location } = useSelector((state) => state.location);
  const { news } = useSelector((state) => state.news);

  const [accordion1, setAccordion1] = useState(false);
  const [accordion2, setAccordion2] = useState(false);
  const [accordion3, setAccordion3] = useState(false);
  const [accordion4, setAccordion4] = useState(false);
  const [accordion5, setAccordion5] = useState(false);
  const [accordion6, setAccordion6] = useState(false);

  const type = "yangiliklar?last_count=10";

  useEffect(() => {
    const fetchSingleService = async () => {
      await dispatch(getSingleServices(id));
    };
    fetchSingleService();
    dispatch(getNews({ type }));
  }, [id, dispatch]);

  useEffect(() => window.scrollTo(0, 0), []);

  return (
    <>
      <div>
        <CommonSection
          title={
            location === "uz"
              ? bolim.name_uz
              : location === "ru"
              ? bolim.name_ru
              : bolim.name_en
          }
          breadCrumb={
            location === "uz"
              ? bolim.name_uz
              : location === "ru"
              ? bolim.name_ru
              : bolim.name_en
          }
        />
        <div className="container mx-auto">
          {id === 5 ? (
            <>
              <div className="xs:w-screen">
                <div className="overflow-y-scroll xs:w-[900px]">
                  <div>
                    <div>
                      <th className="p-5 xs:p-3 w-3/12">
                        Davlat reestr raqami
                      </th>
                      <th className="p-5 xs:p-3 w-3/12">
                        MB va MX Yuridik shaxsning nomi
                      </th>
                      <th className="p-5 xs:p-3 w-3/12">
                        Akkreditatsiya sanasi:
                      </th>
                      <th className="p-5 xs:p-3 w-3/12">Amaldagi holati</th>
                    </div>
                  </div>
                  <div className="bolimlarDetail border border-bd rounded odd:bg-bd">
                    <div className="flex">
                      <th className="p-5 xs:p-3 w-3/12 font-normal">
                        O'ZAK.MS.0059
                      </th>
                      <th className="p-5 xs:p-3 w-3/12 font-normal">
                        "Unicon.uz-fan-tehnika <br /> va marketing tadqiqotlari{" "}
                        <br /> markazi" davlat unitar...
                      </th>
                      <th className="p-5 xs:p-3 w-3/12 font-normal">
                        28 mar 2022
                      </th>
                      <th className="p-5 xs:p-3 w-3/12 font-normal">
                        <span className="border border-hover rounded text-hover p-1">
                          Amaldagi
                        </span>
                      </th>
                    </div>
                    <div className="flex px-12 items-center w-full">
                      <span className="p-5 xs:p-3 w-1/2">
                        Davlat reestr raqami
                      </span>
                      <span className="p-5 xs:p-3 w-1/2">
                        {bolim.state_register_number}
                      </span>
                    </div>
                    <div className="flex px-12 items-center w-full">
                      <span className="p-5 xs:p-3 w-1/2">Amaldagi holati</span>
                      <span className="p-5 xs:p-3 w-1/2">
                        <span className="border border-hover rounded text-hover p-1">
                          Amaldagi
                        </span>
                      </span>
                    </div>
                    <div className="flex px-12 items-center w-full">
                      <span className="p-5 xs:p-3 w-1/2">
                        Yur. shaxsning nomi:
                      </span>
                      <span className="p-5 xs:p-3 w-1/2">
                        {bolim.name_of_the_legal_entity}
                      </span>
                    </div>
                    <div className="flex px-12 items-center w-full">
                      <span className="p-5 xs:p-3 w-1/2">INN:</span>
                      <span className="p-5 xs:p-3 w-1/2">{bolim.itn}</span>
                    </div>
                    <div className="flex px-12 items-center w-full">
                      <span className="p-5 xs:p-3 w-1/2">
                        Yur. shaxsning manzili:
                      </span>
                      <span className="p-5 xs:p-3 w-1/2">
                        {bolim.legal_entity_address}
                      </span>
                    </div>
                    <div className="flex px-12 items-center w-full">
                      <span className="p-5 xs:p-3 w-1/2">Telefon:</span>
                      <span className="p-5 xs:p-3 w-1/2">
                        {bolim.telephone}
                      </span>
                    </div>
                    <div className="flex px-12 items-center w-full">
                      <span className="p-5 xs:p-3 w-1/2">E-pochta:</span>
                      <span className="p-5 xs:p-3 w-1/2">{bolim.email}</span>
                    </div>
                    <div className="flex px-12 items-center w-full">
                      <span className="p-5 xs:p-3 w-1/2">Web sayti:</span>
                      <span className="p-5 xs:p-3 w-1/2">{bolim.web_site}</span>
                    </div>
                    <div className="flex px-12 items-center w-full">
                      <span className="p-5 xs:p-3 w-1/2">
                        Akk. organining boshlig'ini I.SH.O:
                      </span>
                      <span className="p-5 xs:p-3 w-1/2">
                        {bolim.full_name_head_of_accretion_body}
                      </span>
                    </div>
                    <div className="flex px-12 items-center w-full">
                      <span className="p-5 xs:p-3 w-1/2">
                        Akkreditatsiya ob'ektining manzili:
                      </span>
                      <span className="p-5 xs:p-3 w-1/2">
                        {bolim.accreditation_object_address}
                      </span>
                    </div>
                    <div className="flex px-12 items-center w-full">
                      <span className="p-5 xs:p-3 w-1/2">
                        Akkreditatsiya sanasi:
                      </span>
                      <span className="p-5 xs:p-3 w-1/2">
                        {moment(bolim.accreditation_date).format("MMM Do YYYY")}
                      </span>
                    </div>
                    <div className="flex px-12 items-center w-full">
                      <span className="p-5 xs:p-3 w-1/2">
                        Akkreditatsiya doirasiga havola:
                      </span>
                      <a
                        rel="noreferrer"
                        href={bolim.link_to_the_scope_of_accreditation}
                        target={"_blank"}
                        className="p-5 xs:p-3 w-1/2 "
                      >
                        <span className="border border-hover rounded text-hover p-1">
                          {t("download")}
                        </span>
                      </a>
                    </div>
                    <div className="flex px-12 items-center w-full">
                      <span className="p-5 xs:p-3 w-1/2">
                        Akkreditatsiya guvohnomasiga havola
                      </span>
                      <a
                        rel="noreferrer"
                        href={bolim.certificate_of_accreditation}
                        target={"_blank"}
                        className="p-5 xs:p-3 w-1/2 "
                      >
                        <span className="border border-hover rounded text-hover p-1">
                          {t("download")}
                        </span>
                      </a>
                    </div>
                    <div className="flex px-12 items-center w-full">
                      <span className="p-5 xs:p-3 w-1/2">
                        Akkreditatsiya ob'ektining turi:
                      </span>
                      <span className="p-5 xs:p-3 w-1/2">
                        {bolim.type_of_accreditation_object}
                      </span>
                    </div>
                    <div className="flex px-12 items-center w-full">
                      <span className="p-5 xs:p-3 w-1/2">Status sanasi:</span>
                      <span className="p-5 xs:p-3 w-1/2">
                        {moment(bolim.status_date).format("MMM Do YYYY")}
                      </span>
                    </div>
                    <div className="flex px-12 items-center w-full">
                      <span className="p-5 xs:p-3 w-1/2">
                        Akkreditatsiya to`g`risidagi guvohnomaning amal qilish
                        muddati
                      </span>
                      <span className="p-5 xs:p-3 w-1/2">
                        {moment(
                          bolim.validity_period_of_the_accreditation_certificate
                        ).format("MMM Do YYYY")}
                      </span>
                    </div>
                    <div className="flex px-12 items-center w-full">
                      <span className="p-5 xs:p-3 w-1/2">Standart</span>
                      <span className="p-5 xs:p-3 w-1/2">{bolim.standard}</span>
                    </div>
                    <div className="flex px-12 items-center w-full">
                      <span className="p-5 xs:p-3 w-1/2">
                        TJT sertifikatlashtirish organi mahsulotlari uchun
                        muvofiqlikni baholash sxemalari
                      </span>
                      <a
                        rel="noreferrer"
                        href={bolim.file1}
                        target={"_blank"}
                        className="p-5 xs:p-3 w-1/2 "
                      >
                        <span className="border border-hover rounded text-hover p-1">
                          {t("download")}
                        </span>
                      </a>
                    </div>
                    <div className="flex px-12 items-center w-full">
                      <span className="p-5 xs:p-3 w-1/2">
                        Boshqaruv tizimi sifat. Buyurtma hisobga olish
                        murojaatlar
                      </span>
                      <a
                        rel="noreferrer"
                        href={bolim.file2}
                        target={"_blank"}
                        className="p-5 xs:p-3 w-1/2 "
                      >
                        <span className="border border-hover rounded text-hover p-1">
                          {t("download")}
                        </span>
                      </a>
                    </div>
                    <div className="flex px-12 items-center w-full">
                      <span className="p-5 xs:p-3 w-1/2">
                        Mijozlarning majburiyatlari
                      </span>
                      <a
                        rel="noreferrer"
                        href={bolim.file3}
                        target={"_blank"}
                        className="p-5 xs:p-3 w-1/2 "
                      >
                        <span className="border border-hover rounded text-hover p-1">
                          {t("download")}
                        </span>
                      </a>
                    </div>
                    <div className="flex px-12 items-center w-full">
                      <span className="p-5 xs:p-3 w-1/2">
                        O'zbekiston Respublikasi Milliy sertefikatlash
                        tizimidagi ishlar va xizmatlarga to'lov qoidalarini
                        tasdiqlash
                      </span>
                      <a
                        rel="noreferrer"
                        href={"https://lex.uz/docs/2251858"}
                        target={"_blank"}
                        className="p-5 xs:p-3 w-1/2 "
                      >
                        <span className="border border-hover rounded text-hover p-1">
                          {t("download")}
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="box mt-5 flex flex-col gap-3">
                <h1 className="text-4xl font-bold my-5">{t("questions")}</h1>
                <div className="accordion1 p-5 border-bd border rounded-lg">
                  <div
                    className="heading flex justify-between"
                    onClick={() => setAccordion1(!accordion1)}
                  >
                    <h1 className="font-bold hover:text-hover duration-300 cursor-pointer">
                      {t("accordion_head1")}
                    </h1>
                    <span className="text-3xl cursor-pointer">
                      {accordion1 ? "-" : "+"}
                    </span>
                  </div>
                  <div className="body">
                    {accordion1 && (
                      <div className={`${accordion1 ? "mt-5" : ""} text-lg`}>
                        {t("accordion_body1")}
                      </div>
                    )}
                  </div>
                </div>
                <div className="accordion2 p-5 border-bd border rounded-lg">
                  <div
                    className="heading flex justify-between"
                    onClick={() => setAccordion2(!accordion2)}
                  >
                    <h1 className="font-bold hover:text-hover duration-300 cursor-pointer">
                      {t("accordion_head2")}
                    </h1>
                    <span className="text-3xl cursor-pointer">
                      {accordion2 ? "-" : "+"}
                    </span>
                  </div>
                  <div className="body">
                    {accordion2 && (
                      <div className={`${accordion2 ? "mt-5" : ""} text-lg`}>
                        {t("accordion_body2")}
                      </div>
                    )}
                  </div>
                </div>
                <div className="accordion3 p-5 border-bd border rounded-lg">
                  <div
                    className="heading flex justify-between"
                    onClick={() => setAccordion3(!accordion3)}
                  >
                    <h1 className="font-bold hover:text-hover duration-300 cursor-pointer">
                      {t("accordion_head3")}
                    </h1>
                    <span className="text-3xl cursor-pointer">
                      {accordion3 ? "-" : "+"}
                    </span>
                  </div>
                  <div className="body">
                    {accordion3 && (
                      <div className={`${accordion1 ? "mt-5" : ""} text-lg`}>
                        {t("accordion_body3")}
                      </div>
                    )}
                  </div>
                </div>
                <div className="accordion4 p-5 border-bd border rounded-lg">
                  <div
                    className="heading flex justify-between"
                    onClick={() => setAccordion4(!accordion4)}
                  >
                    <h1 className="font-bold hover:text-hover duration-300 cursor-pointer">
                      {t("accordion_head4")}
                    </h1>
                    <span className="text-3xl cursor-pointer">
                      {accordion4 ? "-" : "+"}
                    </span>
                  </div>
                  <div className="body">
                    {accordion4 && (
                      <div className={`${accordion4 ? "mt-5" : ""} text-lg`}>
                        {t("accordion_body4")}
                      </div>
                    )}
                  </div>
                </div>
                <div className="accordion5 p-5 border-bd border rounded-lg">
                  <div
                    className="heading flex justify-between"
                    onClick={() => setAccordion5(!accordion5)}
                  >
                    <h1 className="font-bold hover:text-hover duration-300 cursor-pointer">
                      {t("accordion_head5")}
                    </h1>
                    <span className="text-3xl cursor-pointer">
                      {accordion5 ? "-" : "+"}
                    </span>
                  </div>
                  <div className="body">
                    {accordion5 && (
                      <div className={`${accordion5 ? "mt-5" : ""} text-lg`}>
                        {t("accordion_body5")}
                      </div>
                    )}
                  </div>
                </div>
                <div className="accordion6 p-5 border-bd border rounded-lg">
                  <div
                    className="heading flex justify-between"
                    onClick={() => setAccordion6(!accordion6)}
                  >
                    <h1 className="font-bold hover:text-hover duration-300 cursor-pointer">
                      {t("accordion_head6")}
                    </h1>
                    <span className="text-3xl cursor-pointer">
                      {accordion6 ? "-" : "+"}
                    </span>
                  </div>
                  <div className="body">
                    {accordion6 && (
                      <div className={`${accordion6 ? "mt-5" : ""} text-lg`}>
                        {t("accordion_body6")}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div className="box flex py-5 news_details">
              <div className="w-1/4 pr-5 lg:hidden">
                <div className="sticky top-0">
                  <h3 className="text-2xl font-medium text-logo mb-8">
                    {t("news")}
                  </h3>

                  <div>
                    {news &&
                      news?.result?.map((item, idx) => (
                        <Link
                          id={idx}
                          className="flex min-h-[90px] pb-5 border-b border-[#ccc] mb-5"
                          to={`/news/${item.id}`}
                          onClick={() => window.scrollTo(0, 0)}
                        >
                          {item?.files[0]?.is_video ? (
                            <iframe
                              className="object-cover block h-[70px]"
                              title="video"
                              src={`${item?.files[0]?.video_url}`}
                              width={"100%"}
                              height={"100%"}
                              style={{
                                height: "70px",
                                width: "70px",
                                objectFit: "cover",
                              }}
                              frameBorder={0}
                              allowFullScreen={true}
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            />
                          ) : (
                            <img
                              className="object-cover block h-[70px]"
                              width={"70"}
                              height={"70"}
                              src={`${item?.files[0]?.file}`}
                              alt="rasm"
                            />
                          )}
                          <div className="pl-4">
                            <p className="text-logo hover:text-hover text-xs uppercase mb-2">
                              {location === "uz"
                                ? item?.title_uz?.substring(0, 70)
                                : location === "ru"
                                ? item?.title_ru?.substring(0, 70)
                                : item?.title_en?.substring(0, 70)}
                            </p>
                            <div
                              className="text-logo-secondary hover:text-hover text-xs"
                              dangerouslySetInnerHTML={{
                                __html:
                                  location === "uz"
                                    ? item?.content_uz?.substring(3, 70)
                                    : location === "ru"
                                    ? item?.content_ru?.substring(3, 70)
                                    : item?.content_en?.substring(3, 70),
                              }}
                            />
                          </div>
                        </Link>
                      ))}
                  </div>
                </div>
              </div>

              <div
                className="card w-3/4 lg:w-full py-8 px-16 xl:p-8"
                style={{ border: "1px solid #abb8c3" }}
              >
                {bolim?.images?.length > 0 && (
                  <div className="images w-full gap-10 flex">
                    <div className="right w-full">
                      <Swiper
                        direction={"horizontal"}
                        pagination={{
                          clickable: true,
                        }}
                        coverflowEffect={{
                          rotate: 50,
                          stretch: 0,
                          depth: 100,
                          modifier: 1,
                          slideShadows: true,
                        }}
                        effect={"coverflow"}
                        grabCursor={true}
                        centeredSlides={true}
                        spaceBetween={5}
                        modules={[Pagination, EffectCoverflow, Navigation]}
                        slidesPerView={"auto"}
                        navigation={true}
                        className="mySwiper w-full"
                      >
                        {bolim &&
                          bolim?.images?.map((item) => (
                            <SwiperSlide key={item.id}>
                              <img
                                src={`${item.image}`}
                                alt="img"
                                style={{
                                  height: "250px",
                                  width: "100%",
                                  objectFit: "cover",
                                }}
                              />
                            </SwiperSlide>
                          ))}
                      </Swiper>
                    </div>
                  </div>
                )}
                <div
                  className="content py-5"
                  // style={{border: "1px solid rgb(171, 184, 195)"}}
                >
                  <div className="p-4">
                    <p
                      dangerouslySetInnerHTML={{
                        __html:
                          location === "uz"
                            ? bolim?.department_about_uz
                            : location === "ru"
                            ? bolim?.department_about_ru
                            : bolim?.department_about_en,
                      }}
                    />
                    <br />
                    <p
                      dangerouslySetInnerHTML={{
                        __html:
                          location === "uz"
                            ? bolim?.department_duties_uz
                            : location === "ru"
                            ? bolim?.department_duties_ru
                            : bolim?.department_duties_en,
                      }}
                    />
                    <br />
                    <p
                      dangerouslySetInnerHTML={{
                        __html:
                          location === "uz"
                            ? bolim?.department_activity_uz
                            : location === "ru"
                            ? bolim?.department_activity_ru
                            : bolim?.department_activity_en,
                      }}
                    />
                  </div>
                  <div className="p-4">
                    <h1>{t("contact")}</h1>
                    <div className="flex gap-5 mt-5">
                      {bolim?.phone_number &&
                        bolim?.phone_number.map((item) => (
                          <div key={item.id} className="flex gap-2">
                            <Phone className="w-[23px] object-contain h-[23px]" />
                            <a
                              href={`tel: ${item?.number}`}
                              className="text-[#007AFF]"
                            >
                              {item?.number}
                            </a>
                          </div>
                        ))}
                      <div className="flex gap-2">
                        <Mail className="w-[23px] object-contain h-[23px]" />
                        <a
                          href={`mailto: ${bolim?.email}`}
                          className="text-[#007AFF]"
                        >
                          {bolim?.email}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default BolimlarDetails;

import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";

import {
  navbarReducer,
  productsReducer,
  membersReducer,
  langReducer,
  recourseReducer,
  newsReducer,
  statisticsReducer,
  partnersReducer,
  videoReducer,
  vacancyReducer,
  corruptionReducer,
  docsReducer,
  purchesReducer,
  heroVideoReducer,
  appealReducer,
} from "./reducers/reducers";

const reducers = combineReducers({
  bolimlar: navbarReducer,
  products: productsReducer,
  members: membersReducer,
  recourses: recourseReducer,
  news: newsReducer,
  statistics: statisticsReducer,
  partners: partnersReducer,
  videos: videoReducer,
  location: langReducer,
  vacancies: vacancyReducer,
  corruption: corruptionReducer,
  docs: docsReducer,
  purches: purchesReducer,
  hero: heroVideoReducer,
  appeal: appealReducer,
});

const middleware = [thunk];

const store = createStore(
  reducers,
  {},
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;

import React from "react";
import Structure from "../../assets/structure.png";
import CommonSection from "../../components/Layout/CommonSection";
import {useSelector} from "react-redux";

export default function OrgStructure() {
  const { location } = useSelector((state) => state.location);

  return (
    <section>
      <CommonSection
        title={location === "uz" ? "Tashkiliy tuzilma" : location === "ru" ? "Структура" : "Organizational structure"}
        breadCrumb={location === "uz" ? "Tashkiliy tuzilma" : location === "ru" ? "Структура" : "Organizational structure"}
      />

      <div className="container mx-auto py-5 flex justify-center">
        <div className="mx-auto w-full">
          <img
            className="w-full"
            src={Structure}
            alt="organization structure"
          />
        </div>
      </div>
    </section>
  );
}

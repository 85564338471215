export const preloaderBox = {
    initial: {
        width: "100vw",
        right: 0,
    },
    animate: {
        width: 0,
        opacity: 0,
        transition: {
            when: "afterChildren",
            duration: 1.5,
            delay: 3,
            ease: [0.87, 0, 0.13, 1],
        },
    },
};
export const preloaderInner = {
    initial: {
        width: 0,
        right: 0,
    },
    animate: {
        width: "100vw",
        transition: {
            when: "beforeChildren",
            duration: 1.5,
            ease: [0.87, 0, 0.13, 1],
        },
    },
};
export const preloaderText = {
    initial: { marginTop: -20, opacity: 0 },
    animate: {
        marginTop: 0,
        opacity: 1,
        backgroundSize: "100% 200%",
        transition: {
            duration: 0.7,
            delay: 1.5,
            ease: [0.87, 0, 0.13, 1],
        },
    },
};
export const pageAnimation = {
    initial: {
        x: "-100vw",
        right: 0,
    },
    animate: {
        x: "0",
        transition: {
            when: "beforeChildren",
            duration: 2.5,
            delay: 3,
            ease: [0.87, 0, 0.13, 1],
        },
    },
    exit: {
        x: "100vw",
    },
};

export const pageBannerText = {
    initial: {
        opacity: 0,
        skewY: "10deg",
        y: 200,
    },
    animate: {
        opacity: 1,
        skewY: "0deg",
        y: 0,
        transition: {
            when: "beforeChildren",
            duration: 1.5,
            delay: 3.5,
            ease: [0.87, 0, 0.13, 1],
        },
    },
};
export const pageBannerSubText = {
    initial: {
        opacity: 0,
        y: 50,
    },
    animate: {
        opacity: 1,
        y: 0,
        transition: {
            when: "beforeChildren",
            duration: 1.5,
            delay: 4,
            ease: [0.87, 0, 0.13, 1],
        },
    },
};
export const pageBannerLink = {
    initial: {
        opacity: 0,
        y: 50,
    },
    animate: {
        opacity: 1,
        y: 0,
        transition: {
            when: "beforeChildren",
            duration: 1.5,
            delay: 5,
            ease: [0.87, 0, 0.13, 1],
        },
    },
};
export const socilaAnimation = {
    initial: {
        scale: 0,
    },
    animate: {
        scale: 1,
        transition: {
            when: "beforeChildren",
            duration: 1,
            delay: 5.5,
            ease: [0.87, 0, 0.13, 1],
        },
    },
};

export const scrollDown = {
    initial: {
        opacity: 0,
        y: -100,
    },
    animate: {
        opacity: 1,
        y: 0,
        transition: {
            when: "beforeChildren",
            duration: 1.5,
            delay: 6,
            ease: [0.87, 0, 0.13, 1],
        },
    },
};

export const navbarAnimation = {
    initial: {
        y: "-80px",
    },
    animate: {
        y: "0",
        transition: {
            when: "beforeChildren",
            duration: 1,
            delay: 3.5,
            ease: [0.87, 0, 0.13, 1],
        },
    },
};

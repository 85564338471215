export {default as Home} from './Home/Home'
export {default as News} from './InfoCommunication/News'
export {default as Rahbariyat} from './About/Rahbariyat'
export {default as IlmiyKengash} from './About/IlmiyKengash'
export {default as OurTeam} from './About/Team'
export {default as History} from './About/Tarix'
export {default as Products} from './Home/Products'
// export {default as Resurslar} from './About/Resurslar'
// export {default as Takliflar} from './About/Takliflar'
// export {default as Tariflar} from './About/Tariflar'
export {default as Fotos} from './InfoCommunication/Fotos'
export {default as Videos} from './InfoCommunication/Videos'
export {default as Oav} from './InfoCommunication/OAV'
export {default as PageNotFound} from './Home/404'
export {default as Memorandum} from './InfoCommunication/Memorandum'
export {default as Tour} from './Tour/Tour'
export {default as Register} from './Register/Register'

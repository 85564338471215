import React, { useEffect, useRef, useState } from "react";
import CommonSection from "../../components/Layout/CommonSection";
import VanillaTilt from "vanilla-tilt";
import { useDispatch, useSelector } from "react-redux";
import { getProducts } from "../../redux/actions/actions";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function Tilt(props) {
  const { options, ...rest } = props;
  const tilt = useRef(null);

  useEffect(() => {
    VanillaTilt.init(tilt.current, options);
  }, [options]);

  return <div ref={tilt} {...rest} />;
}

function Products() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { location } = useSelector((state) => state.location);
  const { t } = useTranslation();

  useEffect(() => {
    const fetchProducts = () => {
      setLoading(true);
      try {
        dispatch(getProducts());
        setLoading(false);
      } catch (e) {
        toast.error(e.message);
        console.log(e);
        setLoading(false);
      }
    };
    fetchProducts();
    window.scrollTo(0, 0);
  }, [dispatch]);

  const { products } = useSelector((state) => state.products);

  const options = {
    scale: 1.1,
    speed: 2000,
    max: 10,
  };

  return (
    <>
      <div className="products">
        <CommonSection title={t("xizmatlar")} breadCrumb={t("xizmatlar")} />
        <div className="container mx-auto">
          <div className="box py-6 flex gap-14 flex-wrap">
            {products &&
              products?.map((item) => (
                <Tilt
                  style={{ boxShadow: "0 0 20px 0 rgb(24 50 67 / 12%)" }}
                  options={options}
                  key={item.id}
                  className="py-4 px-5 rounded-xl flex duration-300 w-[30%] gap-5 md:w-[350px] md:flex-wrap products"
                >
                  <img
                    src={item?.icon}
                    alt={item.name}
                    className="w-[53px] object-cover h-[53px]"
                  />
                  <div className="flex flex-col justify-between gap-3">
                    <h1 className="hover:text-hover duration-200 text_from_api">
                      {location === "uz" ? item.name_uz : location === "ru" ? item.name_ru : item.name_en}
                    </h1>
                    <div className="flex items-center gap-5">
                      <Link
                        to={`/products/${item.id}`}
                        className="font-bold hover:text-hover duration-200"
                      >
                        {t("more")}
                      </Link>
                      <Link
                        to={`/useServices/${item.id}`}
                        className="
                      flex rounded-3xl items-center gap-2 px-2 py-2 bg-[#0E0E4B] text-white
                      xs:px-2 xs:py-1 justify-center duration-200 hover:bg-[#00ce87]"
                      >
                        {t("ariza")}
                      </Link>
                    </div>
                  </div>
                </Tilt>
              ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default Products;

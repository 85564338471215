import React from "react";
import CommonSection from "../../components/Layout/CommonSection";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAppealDir, getNews } from "../../redux/actions/actions";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default function Appeal() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { location } = useSelector((state) => state.location);
  const { appeal } = useSelector((state) => state.appeal);
  const { news } = useSelector((state) => state.news);

  const type = "yangiliklar?last_count=10";

  useEffect(() => {
    dispatch(getAppealDir());
    dispatch(getNews({ type }));
  }, [dispatch]);

  return (
    <section className="">
      <CommonSection
        title={
          location === "uz"
            ? "“UNICON.UZ” MChJ Bosh direktori MUROJAATI"
            : location === "ru"
            ? 'Обращение генерального директора ООО "UNICON.UZ"'
            : 'ADDRESS of the General Director of "UNICON.UZ" LLC'
        }
      />

      <div className="container flex mx-auto py-6">
        <div className="w-1/4 pr-5 lg:hidden">
          <div className="sticky top-0">
            <h3 className="text-2xl font-medium text-logo mb-8">{t("news")}</h3>

            <div>
              {news &&
                news?.result?.map((item, idx) => (
                  <Link
                    id={idx}
                    className="flex min-h-[90px] pb-5 border-b border-[#ccc] mb-5"
                    to={`/news/${item.id}`}
                    onClick={() => window.scrollTo(0, 0)}
                  >
                    {item?.files[0]?.is_video ? (
                      <iframe
                        className="object-cover block h-[70px]"
                        title="video"
                        src={`${item?.files[0]?.video_url}`}
                        width={"100%"}
                        height={"100%"}
                        style={{
                          height: "70px",
                          width: "70px",
                          objectFit: "cover",
                        }}
                        frameBorder={0}
                        allowFullScreen={true}
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      />
                    ) : (
                      <img
                        className="object-cover block h-[70px]"
                        width={"70"}
                        height={"70"}
                        src={`${item?.files[0]?.file}`}
                        alt="rasm"
                      />
                    )}
                    <div className="pl-4">
                      <p className="text-logo hover:text-hover text-xs uppercase mb-2">
                        {location === "uz"
                          ? item?.title_uz?.substring(0, 70)
                          : location === "ru"
                          ? item?.title_ru?.substring(0, 70)
                          : item?.title_en?.substring(0, 70)}
                      </p>
                      <div
                        className="text-logo-secondary hover:text-hover text-xs"
                        dangerouslySetInnerHTML={{
                          __html:
                            location === "uz"
                              ? item?.content_uz?.substring(3, 70)
                              : location === "ru"
                              ? item?.content_ru?.substring(3, 70)
                              : item?.content_en?.substring(3, 70),
                        }}
                      />
                    </div>
                  </Link>
                ))}
            </div>
          </div>
        </div>

        <div
          className="card w-3/4 lg:w-full py-8 px-16 xl:p-8"
          style={{ border: "1px solid #abb8c3" }}
        >
          <img
            className="mx-auto mb-6"
            src={appeal[0]?.image}
            alt="Direktor rasmi"
          />
          <div
            className="mb-8"
            dangerouslySetInnerHTML={{
              __html:
                location === "uz"
                  ? appeal[0]?.text_uz
                  : location === "ru"
                  ? appeal[0]?.text_ru
                  : appeal[0]?.text_en,
            }}
          />
        </div>
      </div>
    </section>
  );
}

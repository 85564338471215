import React from 'react';
import SuccessIcon from "../../assets/SuccessIcon";

const SuccessModal = ({text, onclose}) => {
  return (
    <div className="fixed top-0 left-0 w-full h-screen bg-[rgba(0,0,0,0.5)] flex flex-col items-center justify-center z-[99]">
      <div
        className="p-12 text-center flex flex-col items-center justify-center gap-4 bg-white border border-[#abb8c3]"
      >
        <SuccessIcon />
        <h1 className="text-dark text-2xl">{text}</h1>
        <button
          className="px-4 py-2 mt-3 rounded-[4px] bg-[#0E0E4B] text-white xs:px-2 xs:py-1 duration-200 opacity-70 hover:opacity-100"
          onClick={onclose}
        >
          OK
        </button>
      </div>
    </div>
  );
};

export default SuccessModal;
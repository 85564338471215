import React from "react";
import CommonSection from "../../components/Layout/CommonSection";
import { useTranslation } from "react-i18next";
import InputMask from "react-input-mask";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getVacancy, applyVacancy } from "../../redux/actions/actions";
import { toast } from "react-toastify";
import Image from '../../assets/no-results-bg.2d2c6ee3.png'

export default function JobVacancy() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { location } = useSelector((state) => state.location);
  const { vacancies } = useSelector((state) => state.vacancies);

  const [openForm, setOpenForm] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone_number, setPhoneNumber] = useState("");
  const [message, setMessage] = useState("");

  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("");

  const toggle = (i) => {
    if (openForm === i) {
      return setOpenForm(null);
    }
    setOpenForm(i);
  };

  const fileChange = (e) => {
    let file = e.target.files[0];
    let formData = new FormData();
    formData.append("file", file);
    for (let key of formData.entries()) {
      console.log(key + ", " + key);
    }
    setFile(file);
    setFileName(file.name);
  };

  useEffect(() => {
    dispatch(getVacancy());
  }, [dispatch]);

  return (
    <section className="job-vacancy">
      <CommonSection
        title={location === "uz" ? "Bo'sh ish o'rinlari" : location === "ru" ? "Вакансии" : "Vacancies"}
        // breadCrumb={location === "uz" ? "Bo'sh ish o'rinlari" : "Вакансии"}
      />
      <div className="container flex flex-wrap justify-between mx-auto py-5">
        {vacancies.length === 0 ? (
          <div className={"mx-auto w-full text-center"}>
            <img src={Image} alt="" className={"w-1/4 mx-auto"} />
            <h1 className={"text-3xl"}>{t("vacancy")}</h1>
          </div>
        ) : (
          vacancies &&
          vacancies?.map((e, idx) => (
            <div
              key={idx}
              className="w-[49%] xl2:w-full p-3 rounded-md mb-4"
              style={{ boxShadow: "0 0 20px 0 rgb(24 50 67 / 12%)" }}
            >
              <div className="flex justify-between items-center">
                <h3 className="job-name text-xl font-semibold text-logo capitalize">
                  {location === "uz" ? e.position_type_uz : location === "ru" ? e.position_type_ru : e.position_type_en}
                </h3>
                <div className="flex items-center justify-between p-1 px-2 rounded-md bg-logo-secondary text-white">
                  {t("candidates")}:{" "}
                  <span className="block p-[2px] px-2 rounded-md bg-white font-semibold text-logo ml-2">
                    {e.count_candidate}
                  </span>
                </div>
              </div>

              <div className="flex justify-between items-center pt-3 mb-5">
                <p className="department-name text-lg font-medium text-logo-secondary">
                  {location === "uz" ? e.organization_uz : location === "ru" ? e.organization_ru : e.organization_en}
                </p>

                <a
                  rel="noreferrer"
                  title="yuklash"
                  className="underline transparent hover:text-logo-secondary"
                  href={e.file}
                  target={"_blank"}
                >
                  {t("more")}
                </a>
              </div>

              <div className="flex justify-end items-center mb-5">
                <button
                  onClick={() => toggle(idx)}
                  className="px-4 py-2 mt-3 rounded-[4px] bg-[#0E0E4B] text-white xs:px-2 xs:py-1 justify-center duration-200 opacity-70 hover:opacity-100"
                >
                  {t("stay_resume")}
                </button>
              </div>

              <form
                className={
                  openForm === idx ? "flex flex-wrap gap-5 md:gap-3" : "hidden"
                }
                onSubmit={""}
              >
                <div className="relative w-[32%] flex-grow sm:w-full duration-300">
                  <input
                    required
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className="text-xl border-logo p-2  border rounded-md  md:p-1 w-full"
                    style={{ boxShadow: "0 0 20px 0 rgb(24 50 67 / 12%)" }}
                    type="text"
                    placeholder={t("name")}
                    name="name"
                  />
                </div>

                <div className="relative w-[32%] flex-grow sm:w-full duration-300">
                  <input
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="text-xl border-logo p-2  border rounded-md  md:p-1 w-full"
                    style={{ boxShadow: "0 0 20px 0 rgb(24 50 67 / 12%)" }}
                    type="email"
                    placeholder={t("email")}
                    name="email"
                  />
                </div>

                <div className="relative w-[32%] flex-grow sm:w-full duration-300">
                  <InputMask
                    className="text-lg border-logo p-2  border rounded-md  md:p-1 w-full"
                    // mask={"+999 (99) 999 99 99"}
                    placeholder={t("phone")}
                    value={phone_number}
                    style={{ boxShadow: "0 0 20px 0 rgb(24 50 67 / 12%)" }}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    type="tel"
                    name="phone_number"
                  />
                </div>

                <div className="relative w-[66%] flex-grow sm:w-full duration-300">
                  <textarea
                    required
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    className="text-lg border-logo p-2  border rounded-md  md:p-1 w-full"
                    style={{ boxShadow: "0 0 20px 0 rgb(24 50 67 / 12%)" }}
                    placeholder={t("message")}
                    name={t("message")}
                  />
                </div>

                <div className="relative w-[32%] flex-grow sm:w-full duration-300">
                  <div className="flex flex-col items-start">
                    <label className="mb-2" htmlFor="file1">
                      {t("send_resume")} (doc/pdf/docx)
                    </label>
                    <label className="custom-file-upload">
                      <input
                        id="max_id"
                        type="hidden"
                        name="MAX_FILE_SIZE"
                        value="5245880"
                      />
                      <input
                        required
                        onChange={fileChange}
                        accept=".doc,.pdf,.docx"
                        type="file"
                      />
                      {/* <img className="mr-2" src={Upload} alt="upload icon" /> */}
                      {t("file-upload")}
                    </label>
                    {fileName}
                  </div>
                </div>

                <div className="flex justify-end items-center mb-5">
                  <button
                    onClick={(event) => {
                      event.preventDefault();
                      if (!name) {
                        toast.error(
                          "Ismingiz yoki tashkilot nomini kiritmadingiz!"
                        );
                      } else if (!email) {
                        toast.error("Pochtangizni kiritmadingiz!");
                      } else if (!phone_number) {
                        toast.error("Telefon raqamingizni kiritmadingiz!");
                      } else if (!file) {
                        toast.error("File yuklamadingiz!");
                      } else if (!message) {
                        toast.error("Xabarni kiritmadingiz!");
                      } else {
                        const formData = new FormData();
                        formData.append("full_name", name);
                        formData.append("email", email);
                        formData.append("phone_number", phone_number);
                        formData.append("message", message);
                        formData.append("vacancie", e.id);
                        formData.append("file", file);
                        dispatch(applyVacancy(formData));
                        toast.success(
                          "Xabaringiz yuborildi tez orada bo'glanamiz"
                        );
                        setName("");
                        setEmail("");
                        setPhoneNumber("");
                        setMessage("");
                      }
                    }}
                    type={"submit"}
                    className="ml-auto px-4 py-2 mt-3 rounded-[4px] bg-[#0E0E4B] text-white xs:px-2 xs:py-1 justify-center duration-200 opacity-70 hover:opacity-100"
                  >
                    {t("send")}
                  </button>
                </div>
              </form>
            </div>
          ))
        )}
      </div>
    </section>
  );
}

export const GET_NAVBAR = "GET_NAVBAR"
export const GET_SINGLE_SERVICES = 'GET_SINGLE_SERVICES'
export const GET_PRODUCTS = "GET_PRODUCTS"
export const GET_SINGLE_PRODUCTS = 'GET_SINGLE_PRODUCTS'
export const GET_MEMBERS = 'GET_MEMBERS'
export const GET_SINGLE_RESOURCE = 'GET_SINGLE_RESOURCE'
export const GET_RESOURCE = 'GET_RESOURCE'
export const GET_NEWS = 'GET_NEWS'
export const GET_SINGLE_NEWS = 'GET_SINGLE_NEWS'
export const GET_VIDEOS = 'GET_VIDEOS'
export const GET_VIDEO_SINGLE = 'GET_VIDEO_SINGLE'
export const SEND_MESSAGE = 'SEND_MESSAGE'
export const SEND_CONTACT = 'SEND_CONTACT'
export const GET_STATISTICS = 'GET_STATISTICS'
export const GET_PARTNERS = 'GET_PARTNERS'
export const SWITCH_LANG = 'SWITCH_LANG'
export const GET_VACANCY = 'GET_VACANCY'
export const APPLY_VACANCY = 'APPLY_VACANCY'
export const GET_CORRUPTION = 'GET_CORRUPTION'
export const COMMUN_CHANEL = 'COMMUN_CHANEL'
export const SUBSCRIBE = 'SUBSCRIBE'
export const GET_DOCS = 'GET_DOCS'
export const GET_PURCHES = 'GET_PURCHES'
export const GET_HERO_VIDEO = 'GET_HERO_VIDEO'
export const GET_APPEAL = 'GET_APPEAL'
export const SEND_MISTAKE_INFO = 'SEND_MISTAKE_INFO'
